import { Dialog } from "@headlessui/react";
import { DotsHorizontalIcon, ExclamationIcon, XIcon } from "@heroicons/react/solid";
import { userValidator } from "api-neo/User/validator";
import { useAdmin } from "classes/Admin";
import { useUser } from "classes/User";
import { Form } from "components/forms/Form";
import { Dropdown } from "components/utils/Dropdown";
import { Overlay } from "components/utils/Overlay";
import { useRequest } from "hooks/useRequest";
import { fDate, getHourInMin, minutesToHourString } from "libraries/dash";
import { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

export const User = () => {
  const admin = useAdmin();
  const user = useUser();
  const params = useParams();

  const [u, setU] = useState<any>(null);
  const [activeUserOpen, setActiveUserOpen] = useState(false);
  const [addPointUserOpen, setAddPointUserOpen] = useState(false);

  const { req: getUserByAdmin } = useRequest(user.getByAdmin, {
    success: (res) => setU(res.user),
  });

  const { req: getUserBySlug } = useRequest(user.getBySlug, {
    success: (res) => getUserByAdmin({ param: { _id: res.user?._id } }),
  });

  useEffect(() => {
    if (admin.r.isAuthenticated && params.slug) getUserBySlug({ param: { slug: params.slug || "" } });
  }, [admin.r.isAuthenticated, params.slug]);

  const days = [
    { day: "LU", n: 1 },
    { day: "MA", n: 2 },
    { day: "ME", n: 3 },
    { day: "JE", n: 4 },
    { day: "VE", n: 5 },
    { day: "SA", n: 6 },
    { day: "DI", n: 0 },
  ];

  const availabilityInTimezone = user.availabilitiesToDate(u?.availability || []).map((a) => ({
    startDay: a.startDate.getDay(),
    startDayHour: getHourInMin(a.startDate),
    endDay: a.endDate.getDay(),
    endDayHour: getHourInMin(a.endDate),
  }));

  if (!u) return <div />;
  return (
    <div className="flex flex-col w-full p-8 mb-2 bg-gray-50">
      <DeactivateUserModal open={activeUserOpen} setOpen={setActiveUserOpen} u={u} setU={setU} />
      <AddPointsUserModal open={addPointUserOpen} setOpen={setAddPointUserOpen} u={u} setU={setU} />
      <div className="flex items-center">
        <h2 className="text-2xl font-bold">
          <Link to="/users">
            <span className="hover:underline">Volontaires</span>
          </Link>{" "}
          / {u.firstName} {u.lastName}
        </h2>
        <Badge active={u?.active} />
        <Dropdown
          Button={() => (
            <div className="flex items-center justify-center flex-1 w-8 h-8 ml-3 bg-white border rounded-full hover:border-gray-200">
              <DotsHorizontalIcon className="w-6 h-6 text-gray-900" />
            </div>
          )}
          Items={[
            () => (
              <div>
                <p
                  className="px-4 py-1 bg-white rounded-md cursor-pointer hover:bg-gray-50 "
                  onClick={() => setActiveUserOpen(true)}
                >
                  {u.active ? "Desactiver l'utilisateur" : "Activer l'utilisateur"}
                </p>
                <p
                  className="px-4 py-1 bg-white rounded-md cursor-pointer hover:bg-gray-50 "
                  onClick={() => setAddPointUserOpen(true)}
                >
                  Editer points
                </p>
              </div>
            ),
          ]}
        />
      </div>
      <div className="grid grid-cols-1 mt-4 md:grid-cols-2">
        <div className="col-span-1 p-8 bg-white">
          <img src={u.coverPhoto || process.env.PUBLIC_URL + "/volunteer-cover.svg"} className="w-full" />
          <div className="flex flex-row ml-6 -mt-12">
            {u.profilePhoto ? (
              <img className="object-cover w-20 h-20 border rounded-full border-gray-50" src={u.profilePhoto} />
            ) : (
              <p className="flex items-center justify-center w-20 h-20 text-3xl font-bold text-gray-500 bg-white border rounded-full border-gray-50">
                {u.firstName?.[0]}
                {u.lastName?.[0]}
              </p>
            )}
            <a
              href={`${process.env.REACT_APP_FRONTEND_URL}/users/${u.slug}`}
              target="_blank"
              className="self-end ml-auto"
            >
              <p className="font-bold text-teal-500">Voir profil public</p>
            </a>
          </div>

          <div className="flex pb-8 border-b border-gray-50">
            <div className="flex flex-col flex-1">
              <div className="flex">
                <p className="mt-2 text-xl font-bold">
                  {u.firstName} {u.lastName}
                </p>
              </div>
              <div>
                <p className="mt-2 text-sm">
                  <span className="font-bold text-gray-500 uppercase"> Email:</span>{" "}
                  <span className="text-gray-900">{u.email || "Pas d'email"}</span>
                </p>
                <p className="mt-2 text-sm">
                  <span className="font-bold text-gray-500 uppercase"> Date de création:</span>{" "}
                  <span className="text-gray-900">
                    {fDate(u.createdAt, { day: "2-digit", month: "2-digit", year: "2-digit" })}
                  </span>
                </p>
                <p className="mt-2 text-sm">
                  <span className="font-bold text-gray-500 uppercase"> Expertises:</span>{" "}
                  <span className="text-gray-900">{u.expertises.join(", ")}</span>
                </p>
                <p className="mt-2 text-sm">
                  <span className="font-bold text-gray-500 uppercase"> Nombre de points:</span>{" "}
                  <span className="text-gray-900">{u.projectPoints || 0} </span>
                </p>
                <p className="mt-2 text-sm">
                  <span className="font-bold text-gray-500 uppercase"> Description:</span>{" "}
                  <span className="text-gray-900">{u.description || "Pas de description"}</span>
                </p>
                <p className="mt-2 text-sm">
                  <span className="font-bold text-gray-500 uppercase"> Causes soutenues:</span>{" "}
                  <span className="text-gray-900">{u.causes.join(", ")}</span>
                </p>
                {u?.location?.name && (
                  <p className="mt-2 text-sm">
                    <span className="font-bold text-gray-500 uppercase"> Lieu:</span>{" "}
                    <span className="text-gray-900">{u?.location?.name}</span>
                  </p>
                )}
                <div className="flex mt-2 text-sm">
                  <p className="font-bold text-gray-500 uppercase"> Disponibilités:</p>{" "}
                  <p className="ml-2 text-gray-900 ">
                    {days
                      .filter((day) => !availabilityInTimezone?.every((av: any) => av.startDay !== day.n))
                      .map((day: any, i) => (
                        <div className={`pb-1  w-full flex flex-row`}>
                          <p className="w-3 text-gray-900 ">{day.day}:</p>
                          <div className="mr-5"></div>
                          <div className="flex flex-row items-center">
                            <p className="text-gray-900">
                              {availabilityInTimezone
                                .filter((av: any): boolean => av.startDay === day.n)
                                .map(
                                  (av: any) =>
                                    minutesToHourString(av.startDayHour) + "-" + minutesToHourString(av.endDayHour)
                                )
                                .join(", ")}
                            </p>
                          </div>
                        </div>
                      ))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DeactivateUserModal = ({ open, setOpen, u, setU }: any) => {
  const user = useUser();
  const { req: updateActiveUser } = useRequest(user.updateByAdmin, {
    success: (res: any) => {
      setU(res.user);
      setOpen(false);
    },
  });

  const updateActive = () => updateActiveUser({ data: { active: !u?.active }, param: { _id: u?._id } });

  return (
    <Overlay open={open} setOpen={setOpen}>
      <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="w-6 h-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
              {u.active ? "Désactiver" : "Activer"} l'utilisateur {u.firstName} {u.lastName}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Êtes-vous sûr de vouloir {u.active ? "désactiver" : "activer"} cet utilisateur?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={updateActive}
        >
          {u.active ? "Désactiver" : "Activer"}
        </button>
        <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Annuler
        </button>
      </div>
    </Overlay>
  );
};

const AddPointsUserModal = ({ open, setOpen, u, setU }: any) => {
  const user = useUser();
  const { req: updateUser } = useRequest(user.updateByAdmin, {
    success: (res: any) => {
      setOpen(false);
      setU(res.user);
    },
  });

  return (
    <Overlay open={open} setOpen={setOpen}>
      <Form
        submit={(body) => updateUser({ param: { _id: u?._id }, data: { projectPoints: body.projectPoints } })}
        schema={userValidator.UpdateByAdminData}
        reset={open}
        initialBody={{ projectPoints: u?.projectPoints }}
        children={({ inputProps }) => {
          const { value, setValue } = inputProps("projectPoints");
          return (
            <>
              <div className="w-full p-8">
                <div className="flex w-full">
                  <p className="text-xl font-bold truncate ">
                    Ajouter points à {u.firstName} {u.lastName}
                  </p>
                  <div className="p-1 ml-auto rounded-md hover:bg-gray-50">
                    <XIcon className="w-6 h-6 text-gray-500" onClick={() => setOpen(false)} />
                  </div>
                </div>
                <p className="text-sm text-gray-500">
                  possède actuellement <span className="font-bold">{u.projectPoints || 0} points</span>
                </p>
                <div className="flex w-64 mt-4">
                  <input
                    type="number"
                    min={0}
                    max={5000}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    className="flex-1 block px-3 py-2 text-xl border border-gray-100 rounded-md focus:border-gray-100 focus:ring-transparent bg-gray-50"
                  />
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-teal-500 border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Confirmer
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </>
          );
        }}
      />
    </Overlay>
  );
};

const Badge = ({ active }: { active: boolean | null }) => {
  const className =
    active === null ? "bg-gray-100 text-gray-500" : active ? "bg-green-100 text-green-500" : "bg-red-100 text-red-500";
  return <p className={`px-2 py-1 ml-3 rounded-md ${className}`}>{active ? "Actif" : "Inactif"}</p>;
};
