import { useAdmin } from "classes/Admin";
import { Button } from "components/forms/Button";
import { Form } from "components/forms/Form";
import { useRequest } from "hooks/useRequest";
import { Toast } from "hooks/useToast";
import { useState } from "react";

export const Settings = () => {
  const admin = useAdmin();
  const [reset, setReset] = useState(false);
  const { req: changePassword, error: reqError } = useRequest(admin.changePassword, {
    success: () => {
      setError("");
      setReset(!reset);
      Toast.Toast("Mot de passe changé avec succès");
    },
    failure: (error) => setError(error?.message),
  });

  const [error, setError] = useState("");

  const submitChangePassword = (body: any) => {
    if (body.newPassword !== body.confirmNewPassword) setError("Les mots de passe ne correspondent pas");
    else changePassword({ data: { oldPassword: body.oldPassword, newPassword: body.newPassword } });
  };

  return (
    <div className="flex flex-col w-full p-8 bg-gray-50">
      <div className="flex mb-4">
        <h2 className="mb-2 text-2xl font-bold">Paramètres</h2>
      </div>
      <div className="grid grid-cols-2 gap-8">
        <div className="col-span-1">
          <div className="p-8 bg-white">
            <h2 className="mb-2 text-lg font-bold">Changer de mot de passe</h2>
            {error && <p className="p-3 mt-2 text-xs text-red-500 rounded-md bg-red-50">{error}</p>}
            {reqError && <p className="p-3 mt-2 text-xs text-red-500 rounded-md bg-red-50">{reqError.message}</p>}
            <Form
              submit={submitChangePassword}
              initialBody={{}}
              reset={reset}
              children={({ inputProps }) => (
                <div>
                  <p className="mt-2 mb-2 text-sm text-gray-700">Mot de passe actuel</p>{" "}
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Mot de passe actuel
                    </label>
                    <input
                      type="password"
                      value={inputProps("oldPassword").value}
                      onChange={(e) => inputProps("oldPassword").setValue(e.target.value)}
                      required
                      minLength={6}
                      maxLength={100}
                      className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm"
                      placeholder="Mot de passe actuel"
                    />
                  </div>
                  <p className="mt-4 text-sm text-gray-700">Nouveau mot de passe</p>
                  <div className="mt-2 -space-y-px rounded-md shadow-sm">
                    <div>
                      <label htmlFor="email-address" className="sr-only">
                        Nouveau mot de passe
                      </label>
                      <input
                        type="password"
                        value={inputProps("newPassword").value}
                        onChange={(e) => inputProps("newPassword").setValue(e.target.value)}
                        required
                        minLength={6}
                        maxLength={100}
                        className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm"
                        placeholder="Nouveau mot de passe"
                      />
                    </div>
                    <div>
                      <label htmlFor="password" className="sr-only">
                        Confirmer nouveau mot de passe
                      </label>
                      <input
                        type="password"
                        required
                        minLength={6}
                        maxLength={100}
                        value={inputProps("confirmNewPassword").value}
                        onChange={(e) => inputProps("confirmNewPassword").setValue(e.target.value)}
                        className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm"
                        placeholder="Confirmer mot de passe"
                      />
                    </div>
                  </div>
                  <div>
                    <Button className="mt-4 text-right " type="submit">
                      Confirmer
                    </Button>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
