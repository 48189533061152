import {
  Schedule as ScheduleType,
  CountPerMission,
  CreateManyCustom,
  CreateOneWeekly,
  Delete,
  UpdateOneWeeklyNotGeneratedYet,
  GetPageCalendar,
} from "api-neo/Schedule/interfaces";
import { scheduleApi } from "api-neo/Schedule/index";
import Daddy from "./Daddy";
import { useDispatch, useSelector } from "react-redux";
import { CreateFreeSlot, GetPage, Update } from "api-neo/Schedule/interfaces";
import { uniq } from "libraries/dash";
import { AnyAction } from "redux";

/*
  This class does not have to change from a schedule to another
  since these request always exists on neo schedules
*/

type reduxType = { data: any[]; freeSlot: null | ScheduleType };
const initialState = { data: [], freeSlot: null };

export class Schedule extends Daddy {
  private static instance: Schedule;
  private static api = scheduleApi;

  public r: reduxType = initialState;
  public static reducerType = "SET_SCHEDULE";

  public static reducer = (state: reduxType, { type, value }: AnyAction) =>
    type === "SET_SCHEDULE" ? { ...state, ...value } : state || initialState;

  public constructor(session: reduxType, dispatch: any) {
    super(Schedule.reducerType, dispatch);
    if (!Schedule.instance) Schedule.instance = this;
    Schedule.instance.r = session;
    return Schedule.instance;
  }

  public getPage = (data: GetPage) => this.fetch(Schedule.api.GetPage, data);

  public getPageCalendar = (data: GetPageCalendar) =>
    this.fetch(Schedule.api.GetPageCalendar, { ...data, token: true });

  public getPageInRedux = (data: GetPage): void => {
    this.fetch(Schedule.api.GetPage, data).then((res: any) => {
      this.storeInRedux(res);
      return res;
    });
  };

  public update = (data: Update) =>
    this.fetch(Schedule.api.Update, { ...data, token: true, org: true }).then((res: any) => {
      this.storeInRedux({
        data: [...this.r.data.filter((s) => s._id !== res.schedule._id), res.schedule],
      });
      return res;
    });

  public updateOneWeeklyNotGeneratedYet = (data: UpdateOneWeeklyNotGeneratedYet) =>
    this.fetch(Schedule.api.UpdateOneWeeklyNotGeneratedYet, {
      ...data,
      token: true,
      org: true,
    }).then((res: any) => {
      this.storeInRedux({ data: uniq([...this.r.data, res.schedule]) });
      return res;
    });

  public createFreeSlot = (data: CreateFreeSlot) =>
    this.fetch(Schedule.api.CreateFreeSlot, { ...data, token: true, org: true });

  public updateFreeSlot = (data: Update) =>
    this.fetch(Schedule.api.Update, { ...data, token: true, org: true });

  public getFreeSlot = (data: { mission: string }) =>
    this.fetch(Schedule.api.GetPage, { param: { ...data, type: "free-slot" } });

  public createManyCustom = (data: CreateManyCustom) =>
    this.fetch(Schedule.api.CreateManyCustom, { ...data, token: true, org: true });

  public createOneWeekly = (data: CreateOneWeekly) => {
    this.fetch(Schedule.api.CreateOneWeekly, { ...data, token: true, org: true });
  };

  public delete = (data: Delete) => {
    this.fetch(Schedule.api.Delete, { ...data, token: true, org: true }).then((res: any) => {
      this.storeInRedux({
        data: this.r.data.filter((s) => s._id !== data.param._id),
      });
      return res;
    });
  };

  public deleteFreeSlot = (data: Delete) =>
    this.fetch(Schedule.api.Delete, { ...data, token: true, org: true });

  public countPerMission = (data: CountPerMission) =>
    this.fetch(Schedule.api.CountPerMission, data);
}

export const useSchedule = () =>
  new Schedule(
    useSelector((state: any) => state.schedule),
    useDispatch()
  );
