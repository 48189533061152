const classes = {
  container: "grid place-items-center col-span-1",
  active: "bg-black text-white",
  notActive: "text-gray-700  cursor-pointer hover:bg-gray-50",
};

export const Item = ({ page, pageNumber, setPageNumber }: any): JSX.Element => (
  <li
    className={classes.container}
    onClick={() => {
      setPageNumber(page);
    }}
  >
    <p
      className={`grid place-items-center  rounded-full w-10 h-10 max-md:w-8 max-md:h-8 ${
        page === pageNumber ? classes.active : classes.notActive
      }`}
    >
      {page + 1}
    </p>
  </li>
);

export const FirstItem = ({ pageNumber, setPageNumber }: any): JSX.Element => (
  <li
    className={classes.container}
    onClick={() => {
      setPageNumber(0);
    }}
  >
    <p
      className={`grid place-items-center  rounded-full w-10 h-10 max-md:w-8 max-md:h-8 ${
        pageNumber === 0 ? classes.active : classes.notActive
      }`}
    >
      {1}
    </p>
  </li>
);

export const LastItem = ({ total, pageNumber, setPageNumber }: any): JSX.Element =>
  total !== 1 ? (
    <li
      className={classes.container}
      onClick={() => {
        setPageNumber(total - 1);
      }}
    >
      <p
        className={`grid place-items-center  rounded-full w-10 h-10 max-md:w-8 max-md:h-8 ${
          total === pageNumber + 1 ? classes.active : classes.notActive
        }`}
      >
        {total}
      </p>
    </li>
  ) : (
    <></>
  );
