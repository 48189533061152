import { transactionApi } from "api-neo/Transaction";
import Daddy from "./Daddy";
import { useDispatch, useSelector } from "react-redux";
import { GetPage, GetPageStatistics, GiveByAdmin } from "api-neo/Transaction/interfaces";
import { AnyAction } from "redux";

type ApplyMany = {
  mission: string;
  schedules: { _id: string }[];
  weeklySchedules: { _id: string; startDate: Date; endDate: Date }[];
};

type reduxType = { data: any[] };

const initialState = { data: [] };

const reducer = "SET_TRANSACTION";

export class Transaction extends Daddy {
  private static instance: Transaction;
  private static api = transactionApi;
  public static conversionToNumber = (coins: number) => coins / (60 * 8);
  public static conversion = (coins: number) => (coins / (60 * 8)).toFixed(2);
  public static conversionInCoins = (credits: number) => credits * 60 * 8;

  public r: reduxType = initialState;
  public static reducerType = "SET_TRANSACTION";

  public static reducer = (state: reduxType, { type, value }: AnyAction) =>
    type === "SET_TRANSACTION" ? { ...state, ...value } : state || initialState;

  public constructor(session: Transaction["r"], dispatch: any) {
    super(Transaction.reducerType, dispatch);
    if (!Transaction.instance) Transaction.instance = this;
    Transaction.instance.r = session;
    return Transaction.instance;
  }

  public getPage = (data: GetPage) => this.fetch(Transaction.api.GetPage, { ...data, token: true });

  public getPageStatistics = (data: GetPageStatistics) =>
    this.fetch(Transaction.api.GetPageStatistics, { ...data, token: true });

  public giveByAdmin = (data: GiveByAdmin) =>
    this.fetch(Transaction.api.GiveByAdmin, { ...data, token: true });
}

export const useTransaction = () =>
  new Transaction(
    useSelector((state: any) => state.transaction),
    useDispatch()
  );
