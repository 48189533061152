import { useEffect, useState } from "react";
import {
  EndEllipsis,
  StartEllipsis,
  NextButton,
  PreviousButton,
  Item,
  LastItem,
  FirstItem,
} from "./atoms";

/*
 * Pagination component
 *
 * PROPS
 * @total: The number of element in pagination
 * Need a state from parent component "[pageNumber, setPageNumber] = useState<number>()"
 * @pageNumber: Give the actual page
 * @setPageNumber: : Handle the actual page number
 */
export const Pagination = ({ total = 1, pageNumber, setPageNumber }: any): JSX.Element => {
  // a range of total pages
  const [pages, setPages] = useState<any>([]);

  const isTotalPageShort = total <= 7;
  const colsSize = isTotalPageShort ? total : 7;
  const isSiblingStart = pageNumber <= 3 && !isTotalPageShort;
  const isSiblingEnd = pageNumber >= total - 4 && !isTotalPageShort;

  const range =
    total > 1
      ? Array(total - 2)
          .fill(0)
          .map((_: any, i: number) => i + 1)
      : [];

  const handleChange = () => {
    // create initial range
    let pages = range;
    // render only pages number
    if (isTotalPageShort) pages = pages;
    // render first 5 items before ellipsis
    else if (isSiblingStart) pages = pages.filter((p) => p < 5);
    // render last 5 items before ellipsis
    else if (isSiblingEnd) pages = pages.filter((p) => p >= total - 5);
    // is between siblingStart and siblingEnd
    // render 1 ellipsis after first position and one before last position
    else pages = pages.filter((p) => p <= pageNumber + 1 && p >= pageNumber - 1);
    setPages(pages);
  };

  useEffect(() => {
    handleChange();
  }, [pageNumber, total]);

  if (!total || total === 1) return <div />;

  return (
    <nav
      aria-label="pagination navigation"
      className="flex items-center gap-x-1 "
      style={{ width: "auto" }}
    >
      {total > 1 ? <PreviousButton pageNumber={pageNumber} setPageNumber={setPageNumber} /> : <></>}

      <ol
        className={`grid  ${
          total <= 2 ? "w-2" : total <= 4 ? "w-32" : "w-96 "
        } max-sm:w-60 max-md:w-72 gap-x-2`}
        style={{ gridTemplateColumns: `repeat(${colsSize}, 1fr)` }}
      >
        <FirstItem pageNumber={pageNumber} setPageNumber={setPageNumber} />
        <StartEllipsis isTotalPageShort={isTotalPageShort} pages={pages} />
        {pages.map((p: number, i: number) => (
          <Item key={i} page={p} pageNumber={pageNumber} setPageNumber={setPageNumber} />
        ))}
        <EndEllipsis isTotalPageShort={isTotalPageShort} pages={pages} total={total} />
        <LastItem total={total} pageNumber={pageNumber} setPageNumber={setPageNumber} />
      </ol>
      {total > 1 ? (
        <NextButton total={total} pageNumber={pageNumber} setPageNumber={setPageNumber} />
      ) : (
        <></>
      )}
    </nav>
  );
};
