import { messageApi } from "api-neo/Message/index";
import Daddy from "./Daddy";
import { useDispatch, useSelector } from "react-redux";
import { Create, GetPage } from "api-neo/Message/interfaces";
import { uniq } from "libraries/dash";
import { Message as MessageType } from "api-neo/Message/interfaces";
import { AnyAction } from "redux";
/*
  This class does not have to change from a message to another
  since these request always exists on neo messages
*/

type reduxType = { currentConversation: null | string; data: any[]; nbr: number };
const initialState = { currentConversation: null, data: [], nbr: 0 };

export class Message extends Daddy {
  private static instance: Message;
  private static api = messageApi;
  public r: reduxType = initialState;
  public static reducerType = "SET_MESSAGE";

  public static reducer = (state: reduxType, { type, value }: AnyAction) =>
    type === "SET_MESSAGE" ? { ...state, ...value } : state || initialState;

  public constructor(session: reduxType, dispatch: any) {
    super(Message.reducerType, dispatch);
    if (!Message.instance) Message.instance = this;
    Message.instance.r = session;
    return Message.instance;
  }

  public reset = () => {
    this.storeInRedux({ data: [] });
  };

  public getPage = (data: GetPage) => {
    this.r.currentConversation = data.param?.conversation;
    return this.fetch(Message.api.GetPage, { ...data, token: true, org: true });
  };

  public scroll = (data: GetPage) => {
    this.fetch(Message.api.GetPage, { ...data, token: true, org: true }).then((res: any) => {
      const resData = { ...res };
      if (res.page) resData.data = uniq([...this.r.data, ...res.data]);
      this.storeInRedux({ ...resData });
    });
  };

  public create = (data: Create, handleConv: any) =>
    this.fetch(Message.api.Create, { ...data, token: true, org: true }).then((res: any) => {
      if (!res.error) {
        handleConv(res);
        this.storeInRedux({
          data: uniq([res.message, ...this.r.data]),
        });
      }
      return res;
    });

  public handleNewMessage = (data: { message: MessageType }) => {
    if (this.r.currentConversation === data.message.conversation)
      this.storeInRedux({ data: uniq([data.message, ...this.r.data]) });
  };
}

export const useMessage = () =>
  new Message(
    useSelector((state: any) => state.message),
    useDispatch()
  );
