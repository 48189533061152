import { projectApi } from "api-neo/Project";
import Daddy from "./Daddy";
import { useDispatch, useSelector } from "react-redux";
import {
  Project as ProjectType,
  Create,
  GetBySlug,
  GetPage,
  Update,
  UpdateImage,
  DeleteImage,
  GetPageMine,
} from "api-neo/Project/interfaces";
import { uniq } from "libraries/dash";
import { Delete } from "api-neo/Mission/interfaces";
import { AnyAction } from "redux";

/*
  This class does not have to change from a project to another
  since these request always exists on neo projects
*/

type reduxType = { project: null | ProjectType; data: any[] };
const initialState = { project: null, data: [] };

export class Project extends Daddy {
  private static instance: Project;
  private static api = projectApi;

  public r: reduxType = initialState;
  public static reducerType = "SET_PROJECT";

  public static reducer = (state: reduxType, { type, value }: AnyAction) =>
    type === "SET_PROJECT" ? { ...state, ...value } : state || initialState;

  public constructor(session: Project["r"], dispatch: any) {
    super(Project.reducerType, dispatch);
    if (!Project.instance) Project.instance = this;
    Project.instance.r = session;
    return Project.instance;
  }

  public getPage = (data: GetPage) => this.fetch(Project.api.GetPage, data);

  public getPageMine = (data: GetPageMine) =>
    this.fetch(Project.api.GetPageMine, { ...data, token: true });

  public getBySlug = (data: GetBySlug) => this.fetch(Project.api.GetBySlug, data);

  public getBySlugInRedux = (data: GetBySlug): void => {
    this.fetch(Project.api.GetBySlug, data).then((res: any) => {
      if (!res.error) this.storeInRedux(res);
    });
  };

  public create = (data: Create) =>
    this.fetch(Project.api.Create, { ...data, token: true, org: true });

  public update = (data: Update) =>
    this.fetch(Project.api.Update, { ...data, token: true, org: true });

  public deleteCoverPhoto = (data: DeleteImage) =>
    this.fetch(Project.api.DeleteImage, { ...data, token: true, org: true });

  public delete = (data: Delete) =>
    this.fetch(Project.api.Delete, { ...data, token: true, org: true });

  // renvoie un truc bizzare dans la requete [object object]
  public updateCoverPhoto = (data: UpdateImage, objectImage: File) => {
    const { url, ...picData } = this.getPicData(data, objectImage);
    return this.fetch(Project.api.UpdateImage, { ...picData, token: true, org: true });
  };
}

export const useProject = () =>
  new Project(
    useSelector((state: any) => state.project),
    useDispatch()
  );
