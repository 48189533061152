import { FETCH } from "classes/Fetch";
import  { GetById , GetBySlug , GetMyAsso , GetBySlugByAdmin , GetPage , GetPageByAdmin , Create , UpdateMine , UpdateByAdmin , DeleteMyAssociation , AddAdmin , DeleteAdmin , UpdateProfilePhoto , DeleteProfilePhoto , UpdateCoverPhoto , DeleteCoverPhoto } from "./interfaces"

class AssociationApi { 
  private readonly r: string = "association";

  constructor() {}

  public GetById = (data: GetById) => {
    return FETCH({
      path: `${this.r}/get-by-id`,
      ...data,
    });
  };

  public GetBySlug = (data: GetBySlug) => {
    return FETCH({
      path: `${this.r}/get-by-slug`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true},{"t":"Admin"}]
  public GetMyAsso = (data: GetMyAsso) => {
    return FETCH({
      path: `${this.r}/get-my-asso`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Admin"}]
  public GetBySlugByAdmin = (data: GetBySlugByAdmin) => {
    return FETCH({
      path: `${this.r}/get-by-slug-by-admin`,
      ...data,
    });
  };

  public GetPage = (data: GetPage) => {
    return FETCH({
      path: `${this.r}/get-page`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Admin"}]
  public GetPageByAdmin = (data: GetPageByAdmin) => {
    return FETCH({
      path: `${this.r}/get-page-by-admin`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User","a":true}]
  public Create = (data: Create) => {
    return FETCH({
      path: `${this.r}/create`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public UpdateMine = (data: UpdateMine) => {
    return FETCH({
      path: `${this.r}/update-mine`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Admin"}]
  public UpdateByAdmin = (data: UpdateByAdmin) => {
    return FETCH({
      path: `${this.r}/update-by-admin`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true},{"t":"Admin"}]
  public DeleteMyAssociation = (data: DeleteMyAssociation) => {
    return FETCH({
      path: `${this.r}/delete-my-association`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public AddAdmin = (data: AddAdmin) => {
    return FETCH({
      path: `${this.r}/add-admin`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Admin"}]
  public DeleteAdmin = (data: DeleteAdmin) => {
    return FETCH({
      path: `${this.r}/delete-admin`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public UpdateProfilePhoto = (data: UpdateProfilePhoto) => {
    return FETCH({
      path: `${this.r}/update-profilePhoto`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public DeleteProfilePhoto = (data: DeleteProfilePhoto) => {
    return FETCH({
      path: `${this.r}/delete-profilePhoto`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public UpdateCoverPhoto = (data: UpdateCoverPhoto) => {
    return FETCH({
      path: `${this.r}/update-coverPhoto`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public DeleteCoverPhoto = (data: DeleteCoverPhoto) => {
    return FETCH({
      path: `${this.r}/delete-coverPhoto`,
      ...data,
    });
  }; 
}

export const associationApi = new AssociationApi()
