import { useAdmin } from "classes/Admin";
import { useAssociation } from "classes/Association";
import { useMission } from "classes/Mission";
import { useRequest } from "hooks/useRequest";
import { fDate } from "libraries/dash";
import { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

export const Mission = () => {
  const admin = useAdmin();
  const mission = useMission();
  const association = useAssociation();
  const params = useParams();
  const [asso, setAsso] = useState<any>(null);
  const [m, setM] = useState<any>(null);

  const { req: getMissionBySlug } = useRequest(mission.getBySlug, {
    success: (res) => {
      setM(res.mission);
      getMyAsso({ org: res.mission.association._id });
    },
  });

  const { req: getMyAsso } = useRequest(association.getMyAsso, {
    success: (res) => setAsso(res.association),
  });

  useEffect(() => {
    if (admin.r.isAuthenticated && params.slug) getMissionBySlug({ param: { slug: params.slug || "" } });
  }, [admin.r.isAuthenticated, params.slug]);

  if (!asso) return <div />;
  return (
    <div className="flex flex-col w-full p-8 bg-gray-50">
      <div className="flex items-center mb-4">
        <h2 className="mb-2 text-2xl font-bold ">
          <Link to="/associations">
            <span className="hover:underline">Associations</span>
          </Link>{" "}
          /{" "}
          <Link to={`/associations/${asso?.slug}`}>
            <span className="hover:underline">{asso?.name}</span>
          </Link>{" "}
          /{" "}
          <Link to={`/projects/${m.project?.slug}`}>
            <span className="hover:underline">{m?.project?.name}</span>
          </Link>{" "}
          / {m?.name}
        </h2>
        {m?.state === "draft" ? (
          <p className="px-2 py-1 ml-3 text-sm text-gray-700 rounded-md bg-gray-50">Brouillon</p>
        ) : m?.state === "published" ? (
          <p className="px-2 py-1 ml-3 text-sm text-teal-700 bg-teal-100 rounded-md">Publié</p>
        ) : (
          <p className="px-2 py-1 ml-3 text-sm text-red-700 rounded-md bg-red-50">Clôturé</p>
        )}
      </div>
      <div className="grid grid-cols-2 gap-8">
        <div className="col-span-1">
          <div className="p-8 bg-white">
            <div className="flex pb-4">
              <img className="w-full" src={m.image} />
            </div>
            <div className="flex">
              <p className="text-xl font-bold">{m.name}</p>
              <a href={`${process.env.REACT_APP_FRONTEND_URL}/mission/${m.slug}`} className="ml-auto">
                <p className="font-bold text-teal-500">Voir page publique</p>
              </a>
            </div>
            <div className="flex flex-col">
              <p className="mt-2 text-sm">
                <span className="font-bold text-gray-500 uppercase"> Date de création:</span>{" "}
                <span className="text-gray-900">
                  {fDate(m.createdAt, { day: "2-digit", month: "2-digit", year: "2-digit" })}
                </span>
              </p>
              <p className="pb-4 mt-2 text-sm border-b border-gray-50">
                <span className="font-bold text-gray-500 uppercase"> Expertise :</span>{" "}
                <span className="text-gray-900">{m.expertise || "Pas d'expertise"}</span>
              </p>
              <p className="mt-4 text-sm">
                <span className="font-bold text-gray-500 uppercase"> Description :</span>{" "}
                <span className="text-gray-900 whitespace-pre-line">{m.description || "Pas de description"}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="p-8 bg-white">
            <div className="flex flex-col">
              <p className="mt-2 text-sm">
                <span className="font-bold text-gray-500 uppercase"> Lieux :</span>{" "}
                <span className="text-gray-900">{m?.location?.name || "Pas de lieu"}</span>
              </p>
              <p className="mt-2 text-sm">
                <span className="font-bold text-gray-500 uppercase"> Accessibilité :</span>{" "}
                <span className="text-gray-900">
                  {m.disabledPersonAccessibility ? "Accessible à tous" : "Pas accessible à tous"}
                </span>
              </p>
              <p className="mt-2 text-sm">
                <span className="font-bold text-gray-500 uppercase"> Compensation :</span>{" "}
                <span className="text-gray-900">{m.compensation || "Pas de compensation"}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
