import Daddy from "./Daddy";
import { useDispatch, useSelector } from "react-redux";

import { GetMe, Signin, Admin as AdminType, ChangePassword } from "api-neo/Admin/interfaces";
import { AnyAction } from "redux";
import { useEffect } from "react";
import { adminApi } from "api-neo/Admin";

/*
  This class does not have to change from a project to another
  since these request always exists on neo projects
*/

type reduxType = { me: null | AdminType; isAuthenticated: boolean | null };

const initialState = { me: null, isAuthenticated: null };

export class Admin extends Daddy {
  private static instance: Admin;
  public static api = adminApi;

  public r: reduxType = initialState;
  public static reducerType = "SET_ADMIN";

  public static reducer = (state: reduxType, { type, value }: AnyAction) =>
    type === "SET_ADMIN" ? { ...state, ...value } : state || initialState;

  public static triedToConnect: boolean = false;
  public static isAuthenticated: boolean | null = null;

  public constructor(session: Admin["r"], dispatch: any) {
    super(Admin.reducerType, dispatch);
    if (!Admin.instance) Admin.instance = this;
    Admin.instance.r = session;
    return Admin.instance;
  }

  public getMe = (data: GetMe) =>
    this.fetchWithAdminToken(Admin.api.GetMe, { ...data, token: true }).then((res: any) => {
      if (!res?.error) {
        this.storeInRedux({ me: res.admin });
        this.setIsAuthenticated(true);
      }
      return res;
    });

  public signin = (data: Signin) =>
    Admin.api.Signin(data).then((res: any) => {
      if (res.accessToken) localStorage.setItem("adminAccessToken", res.accessToken);
      if (res.refreshToken) localStorage.setItem("adminRefreshToken", res.refreshToken);
      if (res.accessToken && res.refreshToken) this.getMe({});
      return res;
    });

  public changePassword = (data: ChangePassword) =>
    this.fetch(Admin.api.ChangePassword, { ...data, token: true });

  public setIsAuthenticated = (isAuthenticated: boolean) => {
    this.storeInRedux({ isAuthenticated });
  };

  public logout = () => {
    const refreshToken = localStorage.getItem("adminRefreshToken");
    this.fetchWithAdminToken(Admin.api.Logout, {
      param: { refreshToken },
      token: true,
    });
    this.setIsAuthenticated(false);
    localStorage.removeItem("adminRefreshToken");
    localStorage.removeItem("adminAccessToken");
  };

  public tryToConnect = () => {
    Admin.triedToConnect = true;
    const accessToken = localStorage.getItem("adminAccessToken");
    const refreshToken = localStorage.getItem("adminRefreshToken");
    if (!accessToken || !refreshToken) {
      this.setIsAuthenticated(false);
    } else this.getMe({});
  };
}

export const useAdmin = () => {
  const admin = new Admin(
    useSelector((state: any) => state.admin),
    useDispatch()
  );

  useEffect(() => {
    if (admin.r.isAuthenticated === null && Admin.triedToConnect === false) admin.tryToConnect();
  }, []);

  return admin;
};
