import { combineReducers } from "redux";

import { createStore, applyMiddleware } from "redux";

import { composeWithDevTools } from "redux-devtools-extension";
import spy from "redux-monitor-spy";

import { Admin } from "./Admin";
import { Application } from "./Application";
import { Association } from "./Association";
import { Conversation } from "./Conversation";
import { Message } from "./Message";
import { Mission } from "./Mission";
import { Notification } from "./Notification";
import { Project } from "./Project";
import { Review } from "./Review";
import { Schedule } from "./Schedule";
import { Transaction } from "./Transaction";
import { User } from "./User";

const combinedReducer = combineReducers({
  admin: Admin.reducer,
  application: Application.reducer,
  association: Association.reducer,
  conversation: Conversation.reducer,
  message: Message.reducer,
  mission: Mission.reducer,
  notification: Notification.reducer,
  project: Project.reducer,
  review: Review.reducer,
  schedule: Schedule.reducer,
  transaction: Transaction.reducer,
  user: User.reducer,
});

export interface Types {
  admin: Admin["r"];
  application: Application["r"];
  association: Association["r"];
  conversation: Conversation["r"];
  message: Message["r"];
  mission: Mission["r"];
  notification: Notification["r"];
  project: Project["r"];
  review: Review["r"];
  schedule: Schedule["r"];
  transaction: Transaction["r"];
  user: User["r"];
}

const middlewares =
  process.env.NODE_ENV !== "production"
    ? composeWithDevTools(applyMiddleware(spy))
    : applyMiddleware(spy);

export default createStore(combinedReducer, middlewares);
