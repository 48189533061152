import { Dialog } from "@headlessui/react";
import { BookmarkIcon, DotsHorizontalIcon, PlusSmIcon } from "@heroicons/react/outline";
import { ExclamationIcon, MinusSmIcon, PlusIcon, StarIcon, XIcon } from "@heroicons/react/solid";
import { useAdmin } from "classes/Admin";
import { useAssociation } from "classes/Association";
import { useProject } from "classes/Project";
import { useReview } from "classes/Review";
import { Transaction, useTransaction } from "classes/Transaction";
import { Form } from "components/forms/Form";
import { TextArea } from "components/forms/TextArea";
import { TextInput } from "components/forms/TextInput";
import { Pagination } from "components/ui/Pagination";
import { Dropdown } from "components/utils/Dropdown";
import { Overlay } from "components/utils/Overlay";
import { Calendar } from "domains/calendar/Calendar";
import { usePagination } from "hooks/usePagination";
import { useRequest } from "hooks/useRequest";
import { fDate, fTime } from "libraries/dash";
import { useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

export const Association = () => {
  const admin = useAdmin();
  const association = useAssociation();
  const project = useProject();
  const review = useReview();
  const params = useParams();
  const transaction = useTransaction();
  const [asso, setAsso] = useState<any>(null);

  const [addCreditsOpen, setAddCreditsOpen] = useState(false);
  const [deleteAssoOpen, setDeleteAssoOpen] = useState(false);
  const [activeAssoOpen, setActiveAssoOpen] = useState(false);

  const { req: getAssoBySlug } = useRequest(association.getBySlug, {
    success: (res) => getMyAsso({ org: res.association._id }),
  });

  const { req: getMyAsso } = useRequest(association.getMyAsso, {
    success: (res: any) => setAsso(res.association),
  });

  const { pageData, page, setPage } = usePagination(project.getPageMine, {
    pageProps: { org: asso?._id },
    dependencies: [admin.r.isAuthenticated, asso?._id],
    noFetch: !asso?._id,
  });

  const {
    pageData: pageDataTransactions,
    page: pageTransactions,
    setPage: setPageTransactions,
    add: addTransaction,
  } = usePagination(transaction.getPage, {
    pageProps: { org: asso?._id },
    dependencies: [admin.r.isAuthenticated, asso?._id],
    noFetch: !asso?._id,
  });

  const {
    pageData: pageDataReviews,
    page: pageReviews,
    setPage: setPageReviews,
  } = usePagination(review.getPageMine, {
    pageProps: { org: asso?._id },
    dependencies: [admin.r.isAuthenticated, asso?._id],
  });

  useEffect(() => {
    if (admin.r.isAuthenticated && params.slug) getAssoBySlug({ param: { slug: params.slug || "" } });
  }, [admin.r.isAuthenticated, params.slug, addCreditsOpen]);

  if (!asso) return <div />;
  return (
    <div className="flex flex-col w-full p-8 bg-gray-50">
      <AddCreditsModal open={addCreditsOpen} setOpen={setAddCreditsOpen} asso={asso} addTransaction={addTransaction} />
      <DeleteAssoModal open={deleteAssoOpen} setOpen={setDeleteAssoOpen} asso={asso} />
      <DeactivateAssoModal open={activeAssoOpen} setOpen={setActiveAssoOpen} asso={asso} setAsso={setAsso} />
      <div className="flex items-center mb-4">
        <h2 className="mb-2 text-2xl font-bold">
          <Link to="/associations">
            <span className="hover:underline">Associations</span>
          </Link>{" "}
          / {asso?.name}
        </h2>
        <Badge visible={asso.visible} />
        <Dropdown
          Button={() => (
            <div className="flex items-center justify-center flex-1 w-8 h-8 ml-3 bg-white border rounded-full hover:border-gray-200">
              <DotsHorizontalIcon className="w-6 h-6 text-gray-900" />
            </div>
          )}
          Items={[
            () => (
              <p
                className="px-4 py-1 bg-white rounded-md cursor-pointer hover:bg-gray-50 "
                onClick={() => setActiveAssoOpen(true)}
              >
                {asso?.visible ? "Desactiver l'association" : "Activer l'association"}
              </p>
            ),
          ]}
        />

        <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 ml-auto text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
          onClick={() => setDeleteAssoOpen(true)}
        >
          Supprimer l'association
        </button>
      </div>
      <div className="grid grid-cols-2 gap-8">
        <div className="col-span-1">
          <div className="p-8 bg-white">
            <div className="flex pb-8 border-b border-gray-50 ">
              {asso.profilePhoto ? (
                <img className="w-16 h-16 border rounded-full border-gray-50" src={asso.profilePhoto} />
              ) : (
                <p className="flex items-center justify-center w-16 h-16 text-3xl font-bold text-gray-500 border rounded-full border-gray-50">
                  {asso.name?.[0]}
                </p>
              )}
              <div className="flex flex-col flex-1 ml-4">
                <div className="flex">
                  <p className="text-xl font-bold">{asso.name}</p>
                  <a
                    href={`${process.env.REACT_APP_FRONTEND_URL}/associations/${asso.slug}`}
                    target="_blank"
                    className="ml-auto"
                  >
                    <p className="font-bold text-teal-500">Voir profil public</p>
                  </a>
                </div>
                <div>
                  <p className="mt-2 text-sm">
                    <span className="font-bold text-gray-500 uppercase"> Acronyme :</span>{" "}
                    <span className="text-gray-900">{asso.acronym || "Pas d'acronyme"}</span>
                  </p>
                  <p className="mt-2 text-sm">
                    <span className="font-bold text-gray-500 uppercase"> Date de création :</span>{" "}
                    <span className="text-gray-900">
                      {fDate(asso.createdAt, { day: "2-digit", month: "2-digit", year: "2-digit" })}
                    </span>
                  </p>
                  <p className="mt-2 text-sm">
                    <span className="font-bold text-gray-500 uppercase"> Nombre de crédits :</span>{" "}
                    <span className="text-gray-900">{Transaction.conversion(asso.coins)}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-4">
              <p className="mt-2 text-sm">
                <span className="font-bold text-gray-500 uppercase"> Admins :</span>{" "}
                <span className="text-gray-900">
                  {asso.admins.map((a: any) => (
                    <a href={`/users/${a?.slug}`} className="hover:underline bg-gray-50 rounded-md py-1 px-1.5 ml-1">
                      {a?.firstName} {a?.lastName}
                    </a>
                  )) || "Pas d'acronyme"}
                </span>
              </p>
              <p className="mt-2 text-sm">
                <span className="font-bold text-gray-500 uppercase"> Description :</span>{" "}
                <span className="text-gray-900 whitespace-pre-line">{asso.description || "Pas de description"}</span>
              </p>
            </div>
          </div>
          <div className="p-8 mt-8 bg-white">
            <div className="flex flex-col bg-white">
              <h2 className="mb-2 text-xl font-bold">Projets</h2>
              <div className="flex flex-col bg-white">
                <ProjectsHeader />
                {pageData.data.length === 0 && <p>Pas de projet</p>}
                {pageData.data.map((el) => (
                  <ProjectsTableRow el={el} />
                ))}
                <div className="mx-auto mt-4">
                  <Pagination total={pageData.pages} pageNumber={page} setPageNumber={setPage} />
                </div>
              </div>
            </div>
          </div>
          <div className="p-8 mt-8 bg-white">
            <div className="flex flex-col bg-white">
              <div className="flex flex-col bg-white">
                <h2 className="mb-2 text-lg font-bold">Avis de l'association</h2>
                {pageDataReviews.data.length === 0 && <p>Pas d'avis</p>}
                {pageDataReviews.data.map((el) => (
                  <ReviewTableRow el={el} />
                ))}
                <div className="mx-auto mt-4">
                  <Pagination total={pageDataReviews.pages} pageNumber={pageReviews} setPageNumber={setPageReviews} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="p-8 bg-white">
            <div className="flex flex-col pb-8 border-b border-gray-50">
              <div className="flex items-center flex-1 mb-4">
                <h2 className="mb-2 text-lg font-bold">Historique des transactions crédits</h2>
                <button
                  className="flex items-center px-2 py-1 ml-auto bg-gray-100 border-2 border-gray-100 rounded-full cursor-pointer hover:bg-gray-50"
                  onClick={() => setAddCreditsOpen(true)}
                >
                  <PlusIcon className="w-5 h-5 mr-0.5 text-gray-500" />
                  <p className="text-sm font-semibold text-gray-500">Ajouter</p>
                </button>
              </div>
              <TrnasactionsHeader />
              {pageDataTransactions.data.map((el) => (
                <TransactionsTableRow el={el} />
              ))}
              <div className="mx-auto mt-4">
                <Pagination
                  total={pageDataTransactions.pages}
                  pageNumber={pageTransactions}
                  setPageNumber={setPageTransactions}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2">
          <div className="p-8 bg-white">
            <Calendar asso={asso} />
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectsHeader = () => {
  return (
    <div className="grid w-full grid-cols-5 pb-2 mb-2 text-sm font-bold text-gray-500 uppercase border-b-2 border-gray-100">
      <p className="col-span-2">Nom</p>
      <p className="col-span-2">Date de création</p>
      <p className="flex justify-end col-span-1">Missions</p>
    </div>
  );
};
const TrnasactionsHeader = () => {
  return (
    <div className="grid w-full grid-cols-12 pb-2 mb-2 text-sm font-bold text-gray-500 uppercase border-b-2 border-gray-100">
      <p className="col-span-2">Type</p>
      <p className="col-span-5">Note</p>
      <p className="col-span-4">Date </p>
      <p className="flex justify-end col-span-1">Montant</p>
    </div>
  );
};

const ProjectsTableRow = ({ el }: any) => {
  return (
    <div className="grid items-center w-full grid-cols-5 mt-2">
      <div className="flex items-center col-span-2">
        <Link to={`/projects/${el.slug}`}>
          <div className="flex items-center re">
            <p className="ml-2 font-semibold hover:underline">{el?.name}</p>
          </div>
        </Link>
      </div>
      <p className="col-span-2">{fDate(el.createdAt, { day: "2-digit", month: "2-digit", year: "2-digit" })}</p>
      <p className="flex justify-end col-span-1 ">{el.nbrMissions}</p>
    </div>
  );
};

const translateType = (type: string) => {
  const types: { [key: string]: string } = {
    gift: "Cadeau",
    purchase: "Achat",
    expense: "Dépense",
    refund: "Remboursement",
  };
  return types[type];
};

const TransactionsTableRow = ({ el }: any) => {
  return (
    <div className="grid items-center w-full grid-cols-12 mt-2">
      <p className="col-span-2">{translateType(el.type)}</p>
      <div className="flex items-center col-span-5">
        <p>{el.note || "Pas de note"}</p>
      </div>
      <p className="col-span-4">
        {fDate(el.createdAt, { day: "2-digit", month: "2-digit", year: "2-digit" })} -{" "}
        {fTime(el.createdAt, { hour: "2-digit", minute: "2-digit" })}
      </p>
      <p className="flex justify-end col-span-1">{Transaction.conversion(el.amount)}</p>
    </div>
  );
};

const ReviewTableRow = ({ el }: any) => {
  return (
    <div className="flex w-full mt-2">
      {el.volunteer.profilePhoto ? (
        <img className="border rounded-full w-14 h-14 border-gray-50" src={el.volunteer.profilePhoto} />
      ) : (
        <p className="flex items-center justify-center text-3xl font-bold text-gray-500 border rounded-full w-14 h-14 border-gray-50">
          {el.volunteer.firstName?.[0]}
          {el.volunteer.lastName?.[0]}
        </p>
      )}
      <div className="w-full ml-2">
        <div className="flex flex-row">
          <div>
            <p className="text-sm font-bold text-gray-900">
              {el.volunteer.firstName} {el.volunteer.lastName}
            </p>
            <p className="text-xs text-gray-500">participé à {el.mission?.name}</p>
          </div>
          {el.pinned && <BookmarkIcon className="w-6 h-6 ml-auto text-teal-500" />}
        </div>
        <div className="flex mt-1">
          {Array(5)
            .fill(0)
            .map((_: any, i: number) => (
              <StarIcon className={`w-6 h-6 ${el.stars > i ? "text-yellow-500" : "text-gray-100"}`} />
            ))}
        </div>
        <p className="mt-2 text-sm text-gray-500 whitespace-pre-line ">{el.comment}</p>
      </div>
    </div>
  );
};

const AddCreditsModal = ({ open, setOpen, asso, addTransaction }: any) => {
  const transaction = useTransaction();
  const { req: giveByAdmin } = useRequest(transaction.giveByAdmin, {
    success: (res: any) => {
      addTransaction(res?.transaction);
      setOpen(false);
    },
  });
  return (
    <Overlay open={open} setOpen={setOpen}>
      <Form
        submit={(body) =>
          giveByAdmin({
            data: {
              note: body.note,
              amount: Transaction.conversionInCoins(body.amount),
              association: asso?._id,
            },
          })
        }
        reset={open}
        initialBody={{ amount: 0 }}
        children={({ inputProps }) => {
          const { value, setValue } = inputProps("amount");
          return (
            <>
              <div className="w-full p-8">
                <div className="flex w-full">
                  <p className="text-xl font-bold truncate ">Crédits: {asso.name}</p>
                  <div className="p-1 ml-auto rounded-md hover:bg-gray-50">
                    <XIcon className="w-6 h-6 text-gray-500" onClick={() => setOpen(false)} />
                  </div>
                </div>
                <p className="text-sm text-gray-500">
                  possède actuellement <span className="font-bold">{Transaction.conversion(asso.coins)} crédits</span>
                </p>
                <div className="flex w-64 mt-4">
                  <div className="flex flex-col items-center bg-gray-100 border-gray-100 rounded-l-md">
                    <div className="p-1 cursor-pointer h-1/2 hover:bg-teal-500 rounded-tl-md hover:text-white">
                      <PlusSmIcon className="w-4 h-4 text-2xl font-bold " onClick={() => setValue(value + 1)} />
                    </div>
                    <div className="p-1 cursor-pointer h-1/2 hover:bg-teal-500 rounded-bl-md hover:text-white">
                      <MinusSmIcon className="w-4 h-4 text-2xl font-bold" onClick={() => setValue((value || 1) - 1)} />
                    </div>
                  </div>
                  <input
                    type="number"
                    min={0}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    className="flex-1 block px-3 py-2 text-xl border border-gray-100 rounded-r-md focus:border-gray-100 focus:ring-transparent bg-gray-50"
                  />
                </div>
                <TextArea {...inputProps("note")} inputClassName="h-32 mt-4" />
              </div>
              <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Confirmer
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </>
          );
        }}
      />
    </Overlay>
  );
};

const DeleteAssoModal = ({ open, setOpen, asso }: any) => {
  const [name, setName] = useState<string | undefined>("");
  const [error, setError] = useState<string | undefined>("");
  const association = useAssociation();
  const navigate = useNavigate();
  const deleteAsso = () => {
    if (name !== asso?.name) setError("Le nom ne correspont pas");
    else {
      deleteByAdmin({ org: asso?._id });
      setError("");
    }
  };

  const { req: deleteByAdmin } = useRequest(association.deleteByAdmin, {
    success: () => navigate("/associations"),
  });
  return (
    <Overlay open={open} setOpen={setOpen}>
      <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="w-6 h-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
              Supprimer l'association {asso.name}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Êtes-vous sûr de vouloir supprimer cette association? Toutes les données seront supprimée de manière
                permanentes. Cette action n'est pas reversible.
              </p>
              <p className="mt-3 text-sm text-gray-500">Veuillez écrire le nom de l'association pour confirmer:</p>
              <TextInput value={name} setValue={setName} inputClassName={error ? "border-red-500" : ""} />
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={deleteAsso}
        >
          Supprimer
        </button>
        <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Annuler
        </button>
      </div>
    </Overlay>
  );
};

const DeactivateAssoModal = ({ open, setOpen, asso, setAsso }: any) => {
  const association = useAssociation();
  const { req: updateActiveAsso } = useRequest(association.updateByAdmin, {
    success: (res: any) => {
      setAsso(res.association);
      setOpen(false);
    },
  });

  const updateActive = () => updateActiveAsso({ data: { visible: !asso?.visible }, org: asso?._id } as any);

  return (
    <Overlay open={open} setOpen={setOpen}>
      <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="w-6 h-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
              {asso.visible ? "Désactiver" : "Activer"} l'utilisateur {asso.name}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Êtes-vous sûr de vouloir {asso.visible ? "désactiver" : "activer"} cette association?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={updateActive}
        >
          {asso.visible ? "Désactiver" : "Activer"}
        </button>
        <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Annuler
        </button>
      </div>
    </Overlay>
  );
};

const Badge = ({ visible }: { visible: boolean | null }) => {
  const className =
    visible === null
      ? "bg-gray-100 text-gray-500"
      : visible
      ? "bg-green-100 text-green-500"
      : "bg-red-100 text-red-500";
  return (
    <p className={`px-2 py-1 ml-3 rounded-md ${className}`}>
      {visible === null ? "En attente" : visible ? "Active" : "Inactive"}
    </p>
  );
};
