import { FETCH } from "classes/Fetch";
import  { GetPage , MarkAsRead , InviteVolunteersToMission , GetNbrUnread } from "./interfaces"

class NotificationApi { 
  private readonly r: string = "notification";

  constructor() {}

  // Auhorization: [{"t":"User"},{"t":"Association","org":true}]
  public GetPage = (data: GetPage) => {
    return FETCH({
      path: `${this.r}/get-page`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"},{"t":"Association","org":true}]
  public MarkAsRead = (data: MarkAsRead) => {
    return FETCH({
      path: `${this.r}/mark-as-read`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public InviteVolunteersToMission = (data: InviteVolunteersToMission) => {
    return FETCH({
      path: `${this.r}/invite-volunteers-to-mission`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"},{"t":"Association","org":true}]
  public GetNbrUnread = (data: GetNbrUnread) => {
    return FETCH({
      path: `${this.r}/get-nbr-unread`,
      ...data,
    });
  }; 
}

export const notificationApi = new NotificationApi()
