import { useAdmin } from "classes/Admin";
import { useAssociation } from "classes/Association";
import { useMission } from "classes/Mission";
import { useProject } from "classes/Project";
import { Pagination } from "components/ui/Pagination";
import { usePagination } from "hooks/usePagination";
import { useRequest } from "hooks/useRequest";
import { fDate } from "libraries/dash";
import { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

export const Project = () => {
  const admin = useAdmin();
  const project = useProject();
  const association = useAssociation();
  const mission = useMission();
  const params = useParams();
  const [asso, setAsso] = useState<any>(null);
  const [p, setP] = useState<any>(null);

  const { req: getProjectBySlug } = useRequest(project.getBySlug, {
    success: (res) => {
      setP(res.project);
      getMyAsso({ org: res.project.association._id });
    },
  });

  const { req: getMyAsso } = useRequest(association.getMyAsso, {
    success: (res) => setAsso(res.association),
  });

  const { pageData, page, setPage } = usePagination(mission.getPageMine, {
    pageProps: { org: asso?._id, param: { project: p?._id } },
    dependencies: [admin.r.isAuthenticated, asso?._id, p?._id],
    noFetch: !asso?._id || !p?._id || !admin.r.isAuthenticated,
  });

  useEffect(() => {
    if (admin.r.isAuthenticated && params.slug)
      getProjectBySlug({ param: { slug: params.slug || "" } });
  }, [admin.r.isAuthenticated, params.slug]);

  if (!asso) return <div />;
  return (
    <div className="flex flex-col w-full p-8 bg-gray-50">
      <div className="flex mb-4">
        <h2 className="mb-2 text-2xl font-bold">
          <Link to="/associations">
            <span className="hover:underline">Associations</span>
          </Link>{" "}
          /{" "}
          <Link to={`/associations/${asso?.slug}`}>
            <span className="hover:underline">{asso?.name}</span>
          </Link>{" "}
          / {p?.name}
        </h2>
      </div>
      <div className="grid grid-cols-2 gap-8">
        <div className="col-span-1">
          <div className="p-8 bg-white">
            <div className="flex pb-8">
              <img className="w-full" src={p.image} />
            </div>
            <div className="flex flex-col">
              <p className="mt-2 text-sm">
                <span className="font-bold text-gray-500 uppercase"> Cause :</span>{" "}
                <span className="text-gray-900">{p.cause || "Pas d'acronyme"}</span>
              </p>
              <p className="mt-2 text-sm">
                <span className="font-bold text-gray-500 uppercase"> Description :</span>{" "}
                <span className="text-gray-900 whitespace-pre-line">
                  {p.description || "Pas de description"}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="p-8 bg-white">
            <div className="flex flex-col bg-white">
              <h2 className="mb-2 text-xl font-bold">Missions</h2>
              <div className="flex flex-col bg-white">
                <MissionsHeader />
                {pageData.data.map((el) => (
                  <MissionsTableRow el={el} />
                ))}
                <div className="mx-auto mt-4">
                  <Pagination total={pageData.pages} pageNumber={page} setPageNumber={setPage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MissionsHeader = () => {
  return (
    <div className="grid w-full grid-cols-4 pb-2 mb-2 text-sm font-bold text-gray-500 uppercase border-b-2 border-gray-100">
      <p className="col-span-2">Nom</p>

      <div className="flex justify-end col-span-2">
        <p className="col-span-2">Date de création</p>
      </div>
    </div>
  );
};

const MissionsTableRow = ({ el }: any) => {
  return (
    <div className="grid items-center w-full grid-cols-4 mt-2">
      <div className="flex items-center col-span-2">
        <Link to={`/missions/${el.slug}`}>
          <div className="flex items-center re">
            <p className="ml-2 font-semibold hover:underline">{el?.name}</p>
          </div>
        </Link>
      </div>
      <div className="flex justify-end col-span-2">
        <p className="">
          {fDate(el.createdAt, { day: "2-digit", month: "2-digit", year: "2-digit" })}
        </p>
      </div>
    </div>
  );
};
