type TEXTINPUT = {
  label?: string;
  htmlFor?: string;
  name?: string;
  id?: string;
  placeholder?: string;
  type?: "email" | "password" | "text";
  value: string | undefined;
  setValue: (value: string | undefined) => void;
  className?: string;
  inputClassName?: string;
  error?: string;
  required?: boolean;
  min?: number;
  max?: number;
};

export const TextInput = ({
  label,
  htmlFor,
  name,
  id,
  placeholder,
  type = "text",
  value,
  setValue,
  className = "",
  inputClassName = "",
  error,
  required,
  min,
  max,
}: TEXTINPUT) => {
  return (
    <div className={className}>
      <label htmlFor={htmlFor} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="flex mt-1 rounded-md shadow-sm">
        <input
          type=""
          name={name}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          id={id}
          className={`flex-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${inputClassName}`}
          placeholder={placeholder}
          required={required}
          min={min}
          max={max}
        />
      </div>
      {error && <p>{error}</p>}
    </div>
  );
};
