import "./App.css";
import { useAdmin } from "classes/Admin";
import {
  ChartSquareBarIcon,
  UserGroupIcon,
  OfficeBuildingIcon,
  LogoutIcon,
  AdjustmentsIcon,
} from "@heroicons/react/solid";

import { BrowserRouter, Routes, Route, Outlet, useNavigate, Link } from "react-router-dom";
import { useEffect } from "react";
import { Signin } from "routes/Signin";
import { Users } from "routes/Users";
import { Associations } from "routes/Assocations";
import { Association } from "routes/Association";
import { User } from "routes/User";
import { Project } from "routes/Project";
import { Mission } from "routes/Mission";
import { Settings } from "routes/Settings";
import { useToast } from "hooks/useToast";
import { Dashboard } from "routes/Dashboard";

const App = () => {
  useToast();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signin" element={<Signin />} />
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Dashboard />} />
          <Route path="users/:slug" element={<User />} />
          <Route path="users" element={<Users />} />
          <Route path="associations/:slug" element={<Association />} />
          <Route path="associations" element={<Associations />} />
          <Route path="projects/:slug" element={<Project />} />
          <Route path="missions/:slug" element={<Mission />} />
          <Route path="dashboard" element={<div />} />
          <Route path="settings" element={<Settings />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const Layout = () => {
  const admin = useAdmin();
  const navigate = useNavigate();
  useEffect(() => {
    if (admin.r.isAuthenticated === false) navigate("/signin");
  }, [admin.r.isAuthenticated]);

  return (
    <div className="flex flex-row">
      <SideBar />
      <div className="flex flex-col flex-1 h-screen">
        {/* <Header /> */}
        <div className="flex flex-1 overflow-scroll bg-gray-50">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const SideBar = () => {
  const admin = useAdmin();
  return (
    <div className="flex flex-col w-56 h-screen px-6 py-4 border-r-2 border-gray-200">
      <img src="/logo.svg" className="w-24 mb-8" />
      <Link to="/">
        <div className="flex flex-row items-center text-sm text-gray-900 cursor-pointer hover:text-main">
          <ChartSquareBarIcon className="w-5 h-5 mr-1" />
          <p className="text-sm font-medium text-gray-900">Dashboard</p>
        </div>
      </Link>
      <div className="flex flex-row pt-4 text-xs font-bold text-gray-400 uppercase">
        <p>Listes</p>
      </div>
      <Link to="/users">
        <div className="flex flex-row items-center pt-4 text-sm text-gray-900 cursor-pointer hover:text-main">
          <UserGroupIcon className="w-5 h-5 mr-1" />
          <p className="text-sm font-medium text-gray-900">Utilisateurs</p>
        </div>
      </Link>
      <Link to="/associations">
        <div className="flex flex-row items-center pt-4 text-sm text-gray-900 cursor-pointer hover:text-main">
          <OfficeBuildingIcon className="w-5 h-5 mr-1" />
          <p className="font-medium text-gray-900 ">Associations</p>
        </div>
      </Link>
      <Link to="/settings" className="mt-auto">
        <div className="flex flex-row items-center pt-4 mt-auto text-sm text-gray-900 cursor-pointer hover:text-main">
          <AdjustmentsIcon className="w-5 h-5 mr-1" />
          <p className="font-medium text-gray-900 ">Paramètres</p>
        </div>
      </Link>
      <div
        className="flex flex-row items-center pt-4 text-gray-900 cursor-pointer mt-3text-sm hover:text-main"
        onClick={admin.logout}
      >
        <LogoutIcon className="w-5 h-5 mr-1" />
        <p className="text-sm font-medium text-gray-900">Se déconnecter</p>
      </div>
    </div>
  );
};

export default App;
