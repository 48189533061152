import { useState, useEffect, FormEvent, ReactNode } from "react";
import { deepGet, deepSet } from "libraries/dash";
import { deepCopy, isEmpty, objectTester } from "libraries/dash/object";

type StrNum = string | number;

export type Add = (name: StrNum | StrNum[], defaultValue: any) => void;
export type Remove = (name: StrNum | StrNum[], index: number) => void;

export type FormChildrenProps = {
  inputProps: InputProps;
  body: any;
  errors: any;
  add: Add;
  remove: Remove;
  handleSubmit: () => void;
  changes: any;
  isUpdated: boolean;
  modal?: any;
};
export interface FormType {
  initialBody: any;
  reset?: any;
  children: (data: FormChildrenProps) => ReactNode;
  submit: (body: any) => void;
  schema?: any;
  noSubmit?: boolean;
  updated?: boolean;
  showLogs?: boolean;
  t?: (error: string) => string;
  className?: string;
}

export type InputProps = (name: StrNum | StrNum[]) => {
  setValue: (value: any) => void;
  attempted: boolean;
  error: any;
  value: any;
};

/*
 * Form component
 *
 * PROPS
 * @children: Take the input component as children
 * @schema: Make the client-side validation with the schema if provided
 * @initialBody: Take an initial body with the default values of the inputs
 * @submit: Need a button with type 'submit' to submit the form
 *
 * RETURN
 * Gives to the children the inputProps, the body and the errors,
 *
 * @inputProps: a function which takes as argument the name of
 * the input and return its props, the setProp, the error, and
 * the value
 * @error: all the errors of the validation
 * @body: the body of the form
 *
 */
export const Form = ({
  children,
  schema,
  submit,
  initialBody,
  reset,
  noSubmit,
  className,
  updated,
  showLogs,
}: FormType) => {
  // The body created with the initialBody
  const [body, setBody] = useState<any>(deepCopy(initialBody));

  // If the submit action has already been attempted
  const [attempted, setAttempted] = useState<boolean>(false);

  // The object with all the errors
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setBody(deepCopy(initialBody));
    setErrors({});
    setAttempted(false);
  }, [reset]);

  // Validate each time the body is updated
  useEffect(() => {
    if (attempted) validate();
  }, [body]);

  const getChanges = () => {
    const changes: any = {};
    for (const key in body) {
      if (!objectTester(body[key], initialBody[key])) changes[key] = body[key];
    }
    return changes;
  };

  const changes = getChanges();
  const isUpdated = !isEmpty(changes);

  // Generators of the function that update the body
  const setValue = (key: StrNum | StrNum[]) => {
    return (value: any) => {
      deepSet(body, key, value, true);
      setBody({ ...body });
    };
  };

  if (showLogs) {
    console.log(`body`, body);
    console.log(`changes`, changes);
    console.log(`errors`, errors);
  }

  // The props to pass to the inputs
  const inputProps: InputProps = (name) => {
    return {
      // The function to update the body
      setValue: setValue(name),
      // The error of the input
      error: deepGet(errors, name),
      // The value of the body
      value: deepGet(body, name),
      // The attempt
      attempted: attempted,
    };
  };

  // Set the errors and return true if no errors, false otherwise
  const validate: () => boolean = () => {
    if (schema === undefined) return true;
    if (schema.validate(updated ? changes : body)) {
      setErrors({});
      return true;
    }
    setErrors(schema.getError());
    return false;
  };

  // Handle the submit
  const handleSubmit = () => {
    setAttempted(true);
    if (validate()) {
      submit(updated ? changes : body);
    }
  };

  // Handle the submit from form:
  const handleSubmitFromform = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  const add = (name: StrNum | StrNum[], defaultValue: any) => {
    setValue(name)([...(deepGet(body, name) || []), defaultValue]);
  };

  const remove = (name: StrNum | StrNum[], index: number) => {
    setValue(name)([...deepGet(body, name).filter((_: any, i: any) => i !== index)]);
  };

  return (
    <form className={`w-full ${className}`} onSubmit={noSubmit ? () => {} : handleSubmitFromform}>
      {children({
        inputProps,
        body,
        errors,
        add,
        remove,
        handleSubmit,
        changes,
        isUpdated,
      })}
    </form>
  );
};

export const transferInputProps =
  (inputProps: InputProps, initPath: StrNum[]) => (path: StrNum | StrNum[]) => {
    if (typeof path === "string" || typeof path === "number")
      return inputProps([...initPath, path]);
    else return inputProps([...initPath, ...path]);
  };

export const transferAdd =
  (add: Add, initPath: StrNum[]) => (name: StrNum | StrNum[], defaultValue: any) => {
    if (typeof name === "string" || typeof name === "number")
      return add([...initPath, name], defaultValue);
    else return add([...initPath, ...name], defaultValue);
  };

export const transferRemove =
  (remove: Remove, initPath: StrNum[]) => (name: StrNum | StrNum[], index: number) => {
    if (typeof name === "string" || typeof name === "number")
      return remove([...initPath, name], index);
    else return remove([...initPath, ...name], index);
  };
