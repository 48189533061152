import { useState } from "react";

interface ReqProps<T> {
  loading?: boolean;
  success?: (res?: any, body?: any) => void;
  failure?: (data?: any) => void;
}

type ErrorType = { id: any; message: string };

type UseRequestRes<T> = {
  error: ErrorType | null;
  req: (data: T) => any;
  loading: boolean;
};

export const useRequest = <T>(
  req: (data: T) => Promise<any>,
  { loading, success, failure }: ReqProps<T>
): UseRequestRes<T> => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | null>(null);

  const newReq = (data: any) => {
    setSubmitted(true);
    req(data)
      .then((res: any) => {
        if (res.error) {
          setError(res.error);
          failure?.({ error: res.error });
        } else {
          setError(null);
          success?.(res, data);
        }
        setSubmitted(false);
      })
      .catch((error) => {
        console.error("error", error);
        setError({ id: "100", message: "Error in fetch" });
        setSubmitted(false);
      });
  };

  return { error, req: newReq, loading: loading || (submitted && !error) };
};
