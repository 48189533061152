import { FETCH } from "classes/Fetch";
import  { AddDeviceToken , Signup , UpdateByAdmin , UpdateMe , GetPage , GetPageByAdmin , GetPageMyVolunteers , GetRanking , GetMyRankingPosition , GetMe , GetByAdmin , GetBySlug , GetConfirmationEmail , IsEmailInUse , Signin , RefreshToken , Logout , ChangePassword , GetAccessTokenWithCode , ChangeForgottenPassword , DeleteMe , UpdateProfilePhoto , DeleteProfilePhoto , UpdateCoverPhoto , DeleteCoverPhoto } from "./interfaces"

class UserApi { 
  private readonly r: string = "user";

  constructor() {}

  // Auhorization: [{"t":"User"}]
  public AddDeviceToken = (data: AddDeviceToken) => {
    return FETCH({
      path: `${this.r}/add-device-token`,
      ...data,
    });
  };

  public Signup = (data: Signup) => {
    return FETCH({
      path: `${this.r}/signup`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Admin"}]
  public UpdateByAdmin = (data: UpdateByAdmin) => {
    return FETCH({
      path: `${this.r}/update-by-admin`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"}]
  public UpdateMe = (data: UpdateMe) => {
    return FETCH({
      path: `${this.r}/update-me`,
      ...data,
    });
  };

  // Auhorization: "all"
  public GetPage = (data: GetPage) => {
    return FETCH({
      path: `${this.r}/get-page`,
      ...data,
    });
  };

  public GetPageByAdmin = (data: GetPageByAdmin) => {
    return FETCH({
      path: `${this.r}/get-page-by-admin`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public GetPageMyVolunteers = (data: GetPageMyVolunteers) => {
    return FETCH({
      path: `${this.r}/get-page-my-volunteers`,
      ...data,
    });
  };

  public GetRanking = (data: GetRanking) => {
    return FETCH({
      path: `${this.r}/get-ranking`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"}]
  public GetMyRankingPosition = (data: GetMyRankingPosition) => {
    return FETCH({
      path: `${this.r}/get-my-ranking-position`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"}]
  public GetMe = (data: GetMe) => {
    return FETCH({
      path: `${this.r}/get-me`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Admin"}]
  public GetByAdmin = (data: GetByAdmin) => {
    return FETCH({
      path: `${this.r}/get-by-admin`,
      ...data,
    });
  };

  public GetBySlug = (data: GetBySlug) => {
    return FETCH({
      path: `${this.r}/get-by-slug`,
      ...data,
    });
  };

  public GetConfirmationEmail = (data: GetConfirmationEmail) => {
    return FETCH({
      path: `${this.r}/get-confirmation-email`,
      ...data,
    });
  };

  public IsEmailInUse = (data: IsEmailInUse) => {
    return FETCH({
      path: `${this.r}/is-email-in-use`,
      ...data,
    });
  };

  public Signin = (data: Signin) => {
    return FETCH({
      path: `${this.r}/signin`,
      ...data,
    });
  };

  public RefreshToken = (data: RefreshToken) => {
    return FETCH({
      path: `${this.r}/refresh-token`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"}]
  public Logout = (data: Logout) => {
    return FETCH({
      path: `${this.r}/logout`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"}]
  public ChangePassword = (data: ChangePassword) => {
    return FETCH({
      path: `${this.r}/change-password`,
      ...data,
    });
  };

  public GetAccessTokenWithCode = (data: GetAccessTokenWithCode) => {
    return FETCH({
      path: `${this.r}/get-access-token-with-code`,
      ...data,
    });
  };

  public ChangeForgottenPassword = (data: ChangeForgottenPassword) => {
    return FETCH({
      path: `${this.r}/change-forgotten-password`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"}]
  public DeleteMe = (data: DeleteMe) => {
    return FETCH({
      path: `${this.r}/delete-me`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"}]
  public UpdateProfilePhoto = (data: UpdateProfilePhoto) => {
    return FETCH({
      path: `${this.r}/update-profilePhoto`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"}]
  public DeleteProfilePhoto = (data: DeleteProfilePhoto) => {
    return FETCH({
      path: `${this.r}/delete-profilePhoto`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"}]
  public UpdateCoverPhoto = (data: UpdateCoverPhoto) => {
    return FETCH({
      path: `${this.r}/update-coverPhoto`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"}]
  public DeleteCoverPhoto = (data: DeleteCoverPhoto) => {
    return FETCH({
      path: `${this.r}/delete-coverPhoto`,
      ...data,
    });
  }; 
}

export const userApi = new UserApi()
