import { conversationApi } from "api-neo/Conversation/index";
import Daddy from "./Daddy";
import { useDispatch, useSelector } from "react-redux";

import {
  Conversation as ConversationType,
  Create,
  FindConvWithSomeone,
  GetById,
  GetNbrUnreadConv,
  GetPage,
} from "api-neo/Conversation/interfaces";
import { Message } from "api-neo/Message/interfaces";
import { AnyAction } from "redux";
import { uniq } from "libraries/dash";

/*
  This class does not have to change from a conversation to another
  since these request always exists on neo conversations
*/

type reduxType = {
  conversation: null | (ConversationType & { temp: boolean });
  data: any[];
  nbr: number | null;
  owner: string;
};
const initialState = { conversation: null, data: [], nbr: null, owner: "" };

export class Conversation extends Daddy {
  private static instance: Conversation;
  public static api = conversationApi;

  public r: reduxType = initialState;
  public static reducerType = "SET_CONVERSATION";

  public static reducer = (state: reduxType, { type, value }: AnyAction) =>
    type === "SET_CONVERSATION" ? { ...state, ...value } : state || initialState;

  public constructor(session: Conversation["r"], dispatch: any) {
    super(Conversation.reducerType, dispatch);
    if (!Conversation.instance) Conversation.instance = this;
    Conversation.instance.r = session;
    return Conversation.instance;
  }

  public getPage = (data: GetPage) =>
    this.fetch(Conversation.api.GetPage, { ...data, token: true, org: true });

  public scroll = (data: GetPage) => {
    this.fetch(Conversation.api.GetPage, { ...data, token: true, org: true }).then((res: any) => {
      const resData = { ...res };
      if (res.page) resData.data = uniq([...this.r.data.filter((c) => !c.temp), ...res.data]);
      if (this.r.conversation?.temp) resData.data = [this.r.conversation, ...resData.data];
      this.storeInRedux({ ...resData });
    });
  };

  public reset = () => {
    this.storeInRedux({ conversation: null, data: this.r.data.filter((c) => !c.temp) });
  };

  public findConvWithSomeone = (data: FindConvWithSomeone, owner: string) =>
    this.fetch(Conversation.api.FindConvWithSomeone, { ...data, token: true, org: true }).then(
      (res: any) => {
        this.storeInRedux({ ...res, owner });
        return res;
      }
    );

  public selectImage = (el: any, isOrg: string | null) => {
    if (el.image) return el?.image;
    if (el.mission) return el.mission?.image;
    else if (isOrg) return el.users?.[0]?.profilePhoto;
    else return el.associations?.[0]?.profilePhoto;
  };

  public selectName = (el: any, isOrg: string | null) => {
    if (el.name) return el?.name;
    if (el.mission) return el.mission?.name;
    else if (isOrg) return el.users?.[0]?.firstName + " " + el.users?.[0]?.lastName;
    else return el.associations?.[0]?.name;
  };

  public selectConversation = (conv: any, owner: string) => {
    const selectedConversationRead = conv.conversationRead;
    conv.conversationRead = true;
    this.storeInRedux({
      conversation: conv,
      nbr: selectedConversationRead ? this.r.nbr : (this.r.nbr || 0) - 1,
      data: [...this.r.data],
      owner,
    });
  };

  public create = (data: Create, owner: string, association: any) =>
    this.fetch(Conversation.api.Create, { ...data, token: true, org: true }).then((res: any) => {
      this.storeInRedux({
        conversation: { ...res.conversation, associations: [association] },
        owner,
      });
      return res;
    });

  public createTempConversation = (
    data: { allUsers: string[]; users: any[]; associations: any[] },
    owner: string
  ) => {
    const conversation = {
      _id: "",
      temp: true,
      name: "",
      conversationRead: true,
      ...data,
    };
    this.storeInRedux({
      conversation,
      data: [conversation, ...this.r.data],
      owner,
    });
  };

  public getNbrUnreadConv = (data: GetNbrUnreadConv) =>
    this.fetch(Conversation.api.GetNbrUnreadConv, { ...data, token: true, org: true }).then(
      (res: any) => {
        if (!res.error) this.storeInRedux({ nbr: res.nbr });
        return res;
      }
    );

  public isConvActive = () => {
    return this.r.conversation?._id !== undefined;
  };

  public handleSendMessage = (data: { message: Message }) => {
    const convInData = this.r.data.find((c) => c._id === data.message.conversation);
    if (convInData) {
      (convInData as any).lastMessage = data.message;
      (convInData as any).lastEventDate = new Date();
      this.storeInRedux({
        data: [convInData, ...this.r.data.filter((c) => c._id !== convInData._id)],
      });
    }
  };

  public addConversationById = (data: GetById) => {
    this.fetch(Conversation.api.GetById, { ...data, token: true, org: true }).then((res: any) =>
      this.storeInRedux({
        data: [res.conversation, ...this.r.data],
      })
    );
  };

  public handleNewMessage = (data: { message: Message }) => {
    const convInData = this.r.data.find((c) => c._id === data.message.conversation);
    if (convInData) {
      if (
        (convInData as any).conversationRead &&
        data.message.conversation !== this.r.conversation?._id
      )
        this.storeInRedux({ nbr: (this.r.nbr || 0) + 1 });

      // TODO del conversation when get out of messenger
      (convInData as any).conversationRead = data.message.conversation === this.r.conversation?._id;
      (convInData as any).lastMessage = data.message;
      const newData = [convInData, ...this.r.data.filter((c) => c._id !== convInData._id)];

      this.storeInRedux({ data: newData, nbr: this.r.nbr });
    } else {
      if (data.message.conversation) {
        this.addConversationById({ param: { _id: data.message.conversation } });
        if (!(this.r.conversation?._id === data.message.conversation)) this.getNbrUnreadConv({});
      }
    }
  };
}

export const useConversation = () =>
  new Conversation(
    useSelector((state: any) => state.conversation),
    useDispatch()
  );
