import { FETCH } from "classes/Fetch";
import  { Signup , GetMe , GetConfirmationEmail , IsEmailInUse , Signin , RefreshToken , Logout , ChangePassword , GetAccessTokenWithCode , ChangeForgottenPassword , DeleteMe } from "./interfaces"

class AdminApi { 
  private readonly r: string = "admin";

  constructor() {}

  public Signup = (data: Signup) => {
    return FETCH({
      path: `${this.r}/signup`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Admin"}]
  public GetMe = (data: GetMe) => {
    return FETCH({
      path: `${this.r}/get-me`,
      ...data,
    });
  };

  public GetConfirmationEmail = (data: GetConfirmationEmail) => {
    return FETCH({
      path: `${this.r}/get-confirmation-email`,
      ...data,
    });
  };

  public IsEmailInUse = (data: IsEmailInUse) => {
    return FETCH({
      path: `${this.r}/is-email-in-use`,
      ...data,
    });
  };

  public Signin = (data: Signin) => {
    return FETCH({
      path: `${this.r}/signin`,
      ...data,
    });
  };

  public RefreshToken = (data: RefreshToken) => {
    return FETCH({
      path: `${this.r}/refresh-token`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Admin"}]
  public Logout = (data: Logout) => {
    return FETCH({
      path: `${this.r}/logout`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Admin"}]
  public ChangePassword = (data: ChangePassword) => {
    return FETCH({
      path: `${this.r}/change-password`,
      ...data,
    });
  };

  public GetAccessTokenWithCode = (data: GetAccessTokenWithCode) => {
    return FETCH({
      path: `${this.r}/get-access-token-with-code`,
      ...data,
    });
  };

  public ChangeForgottenPassword = (data: ChangeForgottenPassword) => {
    return FETCH({
      path: `${this.r}/change-forgotten-password`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Admin"}]
  public DeleteMe = (data: DeleteMe) => {
    return FETCH({
      path: `${this.r}/delete-me`,
      ...data,
    });
  }; 
}

export const adminApi = new AdminApi()
