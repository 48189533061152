import {
  Mission as MissionType,
  AddOneExceptionToOneWeeklyScheduleTemplate,
  AddWeeklySchedulesTemplate,
  Create,
  Delete,
  DeleteImage,
  DeleteOneWeeklyScheduleTemplate,
  ModifyOneWeeklyScheduleTemplate,
  UpdateImage,
  UpdateState,
  GetPageMine,
  GetPageTemplates,
} from "api-neo/Mission/interfaces";
import { missionApi } from "api-neo/Mission/index";
import Daddy from "./Daddy";
import { useDispatch, useSelector } from "react-redux";
import {
  GetBySlug,
  GetPage,
  UpdateDetails,
  UpdateStartAndEndDates,
} from "api-neo/Mission/interfaces";
import { uniq } from "libraries/dash";
import { AnyAction } from "redux";

/*
  This class does not have to change from a mission to another
  since these request always exists on neo missions
*/

type reduxType = { mission: null | MissionType; data: any[] };
const initialState = { mission: null, data: [] };

export class Mission extends Daddy {
  private static instance: Mission;
  private static api = missionApi;

  public r: reduxType = initialState;
  public static reducerType = "SET_MISSION";

  public static reducer = (state: reduxType, { type, value }: AnyAction) =>
    type === "SET_MISSION" ? { ...state, ...value } : state || initialState;

  public constructor(session: Mission["r"], dispatch: any) {
    super(Mission.reducerType, dispatch);
    if (!Mission.instance) Mission.instance = this;
    Mission.instance.r = session;
    return Mission.instance;
  }

  public reset = () => {
    this.storeInRedux({ mission: null });
  };

  public create = (data: Create) =>
    this.fetch(Mission.api.Create, { ...data, token: true }).then((res: any) => {
      // if (!res?.error) this.storeInRedux(this.updateReduxMission(res));
      return res;
    });

  public getPage = (data: GetPage) => this.fetch(Mission.api.GetPage, data);

  public getPageTemplates = (data: GetPageTemplates) =>
    this.fetch(Mission.api.GetPageTemplates, { ...data, token: true });

  public getPageMine = (data: GetPageMine) =>
    this.fetch(Mission.api.GetPageMine, { ...data, token: true });

  public deleteImage = (data: DeleteImage): void => {
    this.fetch(Mission.api.DeleteImage, data)
      .then(() => ({
        me: { ...this.r.mission, profilePhoto: "" },
      }))
      .catch(console.error);
  };

  public scroll = (data: GetPage) => {
    this.fetch(Mission.api.GetPage, data)
      .then((res: any) => {
        if (res.error) return {};
        const resData = { ...res };
        if (res.pageNumber) resData.data = uniq([...this.r.data, ...res.data]);
        return { ...resData };
      })
      .catch(console.error);
  };

  public getBySlug = (data: GetBySlug) => this.fetch(Mission.api.GetBySlug, data);

  public getBySlugInRedux = (data: GetBySlug): void => {
    this.fetch(Mission.api.GetBySlug, data).then((res: any) => {
      if (!res.error) this.storeInRedux(res);
      return res;
    });
  };

  public updateReduxMission = (res: any) => ({
    mission: {
      ...res.mission,
      project: (this.r.mission as any).project,
      association: (this.r.mission as any).association,
    },
  });

  public updateDetails = (data: UpdateDetails) => {
    return this.fetch(Mission.api.UpdateDetails, {
      ...data,
      token: true,
      org: true,
    })
      .then((res: any) => {
        if (res.error) return;
        this.storeInRedux(this.updateReduxMission(res));
        return res;
      })
      .catch(console.error);
  };

  public updateState = (data: UpdateState) => {
    this.fetch(Mission.api.UpdateState, { ...data, token: true, org: true })
      .then((res: any) => {
        if (!res.error) this.storeInRedux(this.updateReduxMission(res));
      })
      .catch(console.error);
  };

  public updateStartAndEndDate = (data: UpdateStartAndEndDates) =>
    this.fetch(Mission.api.UpdateStartAndEndDates, { ...data, token: true, org: true })
      .then((res: any) => {
        if (!res.error) this.storeInRedux(this.updateReduxMission(res));
        return res;
      })
      .catch(console.error);

  public addWeeklySchedulesTemplate = (data: AddWeeklySchedulesTemplate) =>
    this.fetch(Mission.api.AddWeeklySchedulesTemplate, {
      ...data,
      token: true,
      org: true,
    })
      .then((res: any) => {
        if (!res.error) this.storeInRedux(this.updateReduxMission(res));
        return res;
      })
      .catch(console.error);

  public deleteOneWeeklySchedulesTemplate = (data: DeleteOneWeeklyScheduleTemplate) => {
    this.fetch(Mission.api.DeleteOneWeeklyScheduleTemplate, {
      ...data,
      token: true,
      org: true,
    })
      .then((res: any) => {
        if (!res.error) this.storeInRedux(this.updateReduxMission(res));
      })
      .catch(console.error);
  };

  public delete = (data: Delete) =>
    this.fetch(Mission.api.Delete, { ...data, token: true, org: true });

  public addOneExceptionToOneWeeklyScheduleTemplate = (
    data: AddOneExceptionToOneWeeklyScheduleTemplate
  ) => {
    this.fetch(Mission.api.AddOneExceptionToOneWeeklyScheduleTemplate, {
      ...data,
      token: true,
      org: true,
    })
      .then((res: any) => {
        if (!res.error) this.storeInRedux(this.updateReduxMission(res));
      })
      .catch(console.error);
  };

  public modifyOneWeeklyScheduleTemplate = (data: ModifyOneWeeklyScheduleTemplate) =>
    this.fetch(Mission.api.ModifyOneWeeklyScheduleTemplate, {
      ...data,
      token: true,
      org: true,
    })
      .then((res: any) => {
        if (!res.error) this.storeInRedux(this.updateReduxMission(res));
        return res;
      })
      .catch(console.error);

  public updateCoverPhoto = (data: UpdateImage, objectImage: File) => {
    const { url, ...picData } = this.getPicData(data, objectImage);
    return this.fetch(Mission.api.UpdateImage, {
      ...picData,
      token: true,
      org: true,
    })
      .then((res: any) => {
        if (!res.error) this.storeInRedux({ mission: { ...this.r.mission, image: url } });
        return res;
      })
      .catch(console.error);
  };
}

export const useMission = () =>
  new Mission(
    useSelector((state: any) => state.mission),
    useDispatch()
  );
