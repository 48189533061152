import { useAdmin } from "classes/Admin";
import { Button } from "components/forms/Button";
import { Form } from "components/forms/Form";
import { useRequest } from "hooks/useRequest";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Signin = () => {
  const navigate = useNavigate();
  const admin = useAdmin();
  const { req: signin, error } = useRequest(admin.signin, { success: () => {} });

  useEffect(() => {
    if (admin.r.isAuthenticated) navigate("/");
  }, [admin.r.isAuthenticated]);

  return (
    <div className="flex items-center h-screen">
      <div className="m-auto border border-gray-100s w-96 rounded-xl">
        <Form
          submit={(body: any) => signin({ data: { ...body } })}
          initialBody={{}}
          children={({ inputProps }) => (
            <div>
              <div className="p-6">
                <h1 className="text-2xl font-bold text-gray-900">Connexion</h1>
                <p className="text-sm text-gray-500">Bienvenue sur l'admin Umengo</p>
                {error && (
                  <p className="p-3 mt-2 text-xs text-red-500 rounded-md bg-red-50">
                    {error?.message}
                  </p>
                )}
                <div className="mt-4 -space-y-px rounded-md shadow-sm">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Adress email
                    </label>
                    <input
                      type="email"
                      value={inputProps("email").value}
                      onChange={(e) => inputProps("email").setValue(e.target.value)}
                      autoComplete="email"
                      required
                      className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Email"
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Mot de passe
                    </label>
                    <input
                      type="password"
                      required
                      autoComplete="password"
                      value={inputProps("password").value}
                      onChange={(e) => inputProps("password").setValue(e.target.value)}
                      className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Mot de passe"
                    />
                  </div>
                </div>
              </div>
              <Button className="px-3 py-4 text-right bg-gray-50 sm:px-6" type="submit">
                Se connecter
              </Button>
            </div>
          )}
        />
      </div>
    </div>
  );
};
