import { useUser } from "classes/User";
import { Pagination } from "components/ui/Pagination";
import { usePagination } from "hooks/usePagination";
import { fDate } from "libraries/dash";
import { Link } from "react-router-dom";

export const Users = () => {
  const user = useUser();
  const { pageData, page, setPage } = usePagination(user.getPageByAdmin, { pageProps: { param: { perPage: 20 } } });

  return (
    <div className="flex flex-col w-full p-8 bg-gray-50">
      <h2 className="mb-2 text-2xl font-bold">Utilisateurs</h2>
      <div className="flex flex-col p-8 bg-white">
        <Header />
        {pageData.data.map((el) => (
          <TableRow el={el} />
        ))}
        <div className="mx-auto mt-4">
          <Pagination total={pageData.pages} pageNumber={page} setPageNumber={setPage} />
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div className="grid w-full grid-cols-8 pb-2 mb-2 text-sm font-bold text-gray-500 uppercase border-b-2 border-gray-100">
      <p className="col-span-3">Utilisateur</p>
      <p className="col-span-1">Membre depuis</p>
      <p className="col-span-2 text-right">Volontaire</p>
      <p className="col-span-2 text-right">Statut</p>
    </div>
  );
};

const TableRow = ({ el }: any) => {
  return (
    <div className="grid items-center w-full grid-cols-8 mt-2">
      <div className="flex items-center col-span-3">
        <Link to={`/users/${el.slug}`}>
          <div className="flex">
            {el.profilePhoto ? (
              <img src={el.profilePhoto} className="rounded-full w-9 h-9" />
            ) : (
              <p className="flex items-center justify-center text-xs border border-gray-200 rounded-full w-9 h-9">
                {el.firstName?.[0]}
                {el.lastName?.[0]}
              </p>
            )}
            <div className="">
              <p className="ml-2 font-semibold hover:underline">
                {el?.firstName} {el.lastName}
              </p>
              <p className="ml-2 text-sm leading-3 text-gray-500">{el.email}</p>
            </div>
          </div>
        </Link>
      </div>
      <p className="col-span-1">{fDate(el.createdAt, { day: "2-digit", month: "2-digit", year: "2-digit" })}</p>
      <p className="flex col-span-2 ">
        <div className="ml-auto">
          <BadgeVolunteer active={el?.volunteer} />
        </div>
      </p>
      <p className="flex items-end col-span-2 ">
        <div className="ml-auto">
          <Badge active={el?.active} />
        </div>
      </p>
    </div>
  );
};

const Badge = ({ active }: any) => {
  const className = active ? "bg-green-50 text-green-500" : "text-red-500 bg-red-50";
  return <p className={`px-3 py-1 rounded-md w-min ${className}`}>{active ? "Actif" : "Inactif"}</p>;
};

const BadgeVolunteer = ({ active }: any) => {
  const className = active ? "bg-green-50 text-green-500" : "text-gray-500 bg-gray-50";
  return (
    <p className={`px-3 py-1 rounded-md w-min ${className} whitespace-nowrap`}>
      {active ? "Volontaire" : "Utilisateur"}
    </p>
  );
};
