export const getDatesBetween = (from: Date, to: Date) => {
  if (!from || !to) return [];
  if (typeof from.getFullYear !== "function") return [];
  if (typeof to.getFullYear !== "function") return [];

  // Max two years of interval
  if (to.getFullYear() - from.getFullYear() > 2) return [];
  const dates = [];
  for (var date = new Date(from); date <= new Date(to); date.setDate(date.getDate() + 1)) {
    dates.push(new Date(date));
  }
  return dates;
};

export const dateToDay = (date: Date) => {
  return fDate(date, { day: "2-digit", month: "2-digit", year: "numeric" });
};

export const getDayOfYear = (date: Date) => {
  const _date = new Date(date);
  var start = new Date(_date.getFullYear(), 0, 0).getTime();
  var diff = _date.getTime() - start;
  var oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
};

export const isOver = (date: Date) => new Date(date).getTime() < new Date().getTime();

export const maxDate = (d1: Date, d2: Date) =>
  new Date(d1).getTime() > new Date(d2).getTime() ? new Date(d1) : new Date(d2);

export const getMonthsBetween = (from: Date, to: Date) => {
  if (!from || !to) return [];
  if (typeof from.getFullYear !== "function") return [];
  if (typeof to.getFullYear !== "function") return [];
  if (to.getFullYear() - from.getFullYear() > 3) return [];
  const dates = [];
  for (var date = new Date(from); date <= new Date(to); date.setMonth(date.getMonth() + 1)) {
    dates.push(new Date(date));
  }
  return dates;
};

export const dateEq = (d1: Date, d2: Date) => {
  return new Date(d1).getTime() === new Date(d2).getTime();
};

export const sortDate = (d1: Date, d2: Date) => {
  return d1.getTime() - d2.getTime();
};

export const toDate = (d?: Date | null) => {
  return d ? new Date(d) : undefined;
};

export const mergeDateMin = (date: Date, minutes: number) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), Math.floor(minutes / 60), minutes % 60);
};

export const getDayOf = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const isPassed = (date: string | Date) => {
  return new Date(date).getTime() < new Date().getTime();
};

export const getAge = (birthDate: Date) => {
  const age = new Date(new Date().getTime() - new Date(birthDate).getTime()).getUTCFullYear() - 1970;
  return age.toFixed();
};

export const getDayAndMonth = (date: any) => {
  if (date !== null) {
    const DAY = date.getDate();
    const MONTH = date.getMonth();
    const localDay = DAY.toString().length === 1 ? `0${DAY}` : DAY;
    const localMonth = MONTH.toString().length === 1 ? `0${MONTH}` : MONTH;
    return `${localDay}/${localMonth}`;
  }
  return;
};

export const getDaysOfMonthByWeek = (year: number, month: number) => {
  const day = getFirstDayOfWeek(getFirstDayOfMonth(year, month));
  const days = [];
  while (day.getMonth() !== (month + 1) % 12) {
    days.push(getDaysOfWeek(day));
    day.setDate(day.getDate() + 7);
  }
  return days;
};

export const getDaysOfMonth = (year: number, month: number) => {
  const day = getFirstDayOfMonth(year, month);
  const days = [];
  while (day.getMonth() !== (month + 1) % 12) {
    days.push(new Date(day));
    day.setDate(day.getDate() + 1);
  }
  return days;
};

export const generateForAllDatesBetween = (from: Date, to: Date, generationFunc: (date: Date) => any) => {
  return getDatesBetween(from, to).map(generationFunc);
};

export const fDate = (date: Date, options: any, local?: string | undefined) => {
  return new Date(date).toLocaleDateString(local || "fr-FR", options);
};

export const areOnSameYear = (d1: Date, d2: Date) => {
  return new Date(d1).getFullYear() === new Date(d2).getFullYear();
};

export const fDateNum = (date: Date) => {
  return new Date(date).toLocaleDateString(undefined, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
};

export const fTime = (date: Date, options: any, local?: any) => {
  return new Date(date).toLocaleTimeString(local, options);
};

export const getFirstDayOfMonth = (year: number, month: number) => {
  return new Date(year, month, 1);
};

export const getFirstDayOfMonthFromDate = (date: Date) => {
  return getFirstDayOfMonth(date.getFullYear(), date.getMonth());
};

export const getEndOfMonth = (date: Date) => {
  const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  return new Date(nextMonth.getTime() - 1);
};
export const getEndOfYear = (date: Date) => {
  const nextMonth = new Date(date.getFullYear() + 1, 0, 1);
  return new Date(nextMonth.getTime() - 1);
};

export const addMonth = (date: Date, add?: number) => {
  const d = new Date(date);
  return new Date(d.setMonth(d.getMonth() + (add || 1)));
};

export const addDay = (date: Date, add?: number) => {
  const d = new Date(date);
  return new Date(d.setDate(d.getDate() + (add || 1)));
};

export const addYear = (date: Date, add?: number) => {
  const d = new Date(date);
  return new Date(d.setFullYear(d.getFullYear() + (add || 1)));
};

export const getFirstDayOfWeek = (date: Date) => {
  const newDate = new Date(date);
  const day = date.getDay(); // Get current day number, converting Sun. to 7
  if (day !== 0)
    // Only manipulate the date if it isn't Mon.
    newDate.setHours(-24 * day);
  return newDate;
};

export const getDaysOfWeek = (date: Date) => {
  const day = getFirstDayOfWeek(date);
  const days = [new Date(day)];
  day.setDate(day.getDate() + 1);
  while (day.getDay() !== 0) {
    days.push(new Date(day));
    day.setDate(day.getDate() + 1);
  }
  return days;
};

export const getHourInMin = (date: Date) => {
  const d = new Date(date);
  return d.getHours() * 60 + d.getMinutes();
};

export const getDateWithDayOfWeek = (weekDay?: number, hours?: number, minutes?: number) => {
  const d = getDayOf(new Date());
  if (!weekDay && weekDay !== 0) return d;
  var distance = weekDay - new Date().getDay() + 7;
  if (hours) d.setHours(hours);
  if (minutes) d.setMinutes(minutes);
  d.setDate(d.getDate() + distance);
  return d;
};

export const getDateWithDayOfWeekUTC = (weekDay?: number, hours?: number, minutes?: number) => {
  const d = new Date(Date.UTC(2022, 1, 1));
  if (!weekDay && weekDay !== 0) return d;
  var distance = weekDay - d.getUTCDay() + 7;
  if (hours) d.setUTCHours(hours);
  if (minutes) d.setUTCMinutes(minutes);
  d.setUTCDate(d.getUTCDate() + distance);
  return d;
};

export const setHourInMinUTC = (date: Date, mins?: number) => {
  const d = new Date(date);
  if (!mins) return d;
  d.setUTCHours(Math.floor(mins / 60));
  d.setUTCMinutes(mins % 60);
  return d;
};

export const setHourInMin = (date: Date, mins?: number) => {
  const d = new Date(date);
  if (!mins) return d;
  d.setHours(Math.floor(mins / 60));
  d.setMinutes(mins % 60);
  return d;
};

export const includeDate = (list: Date[], date: Date) => {
  return !list.every((d) => !datesAreOnSameDay(d, date));
};

export const isGreater = (greater?: Date | null, to?: Date | null) => {
  if (!greater || !to) return false;
  return new Date(greater).getTime() > new Date(to).getTime();
};

export const getDaysInMonth = (month: number, year: number) => {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const hoursStringToMinutes = (hoursString: string) => {
  const [hour, minutes] = hoursString.split(":");
  return parseInt(hour) * 60 + parseInt(minutes);
};

export const isOnWeekDay = (d1?: Date, day?: number) => {
  if (!d1 || !day) return false;
  return new Date(d1).getDay() === day;
};

export const minutesToHourString = (time: number) => {
  const hours = Math.floor(time / 60).toFixed(0);
  const minutes = `${time % 60}`;
  let formattedHours = "";
  let formattedMinutes = "";

  if (hours.length === 1) {
    formattedHours = "0" + hours;
  } else {
    formattedHours = hours;
  }
  if (minutes.length === 1) {
    formattedMinutes = "0" + minutes;
  } else {
    formattedMinutes = minutes;
  }
  return `${formattedHours}:${formattedMinutes}`;
};

export const dateDiff = (d1: Date, d2: Date) => {
  return new Date(d1).getTime() - new Date(d2).getTime();
};
