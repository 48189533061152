import { adminApi } from "api-neo/Admin";

/*
  Daddy handle the double auth jwt token authorization and is the first person
  to handle the request response before let the main responsable class do its job

  It aims to have only reusable code from one project to another
*/

export default abstract class Daddy {
  protected constructor(protected readonly reducerType: string, protected dispatch: any) {}

  protected fetch = (request: any, data: any) => {
    if (data.token) {
      return this.fetchWithAdminToken(request, data);
    } else return request(data);
  };

  private useRefreshTokenAdmin = ({ request, data, refreshToken }: any) =>
    adminApi.RefreshToken({ data: { refreshToken } }).then((res: any) => {
      if (res.accessToken) {
        localStorage.setItem("adminAccessToken", res.accessToken);
        data.token = res.accessToken;
        return request(data);
      } else this.cleanStorageAdmin();
      return res;
    });

  protected fetchWithAdminToken = (request: any, data: any) => {
    if (data.token) {
      data.token = localStorage.getItem("adminAccessToken");
      console.log("adminAccessToken", localStorage.getItem("adminAccessToken"));
    }

    return request(data)
      .then((res: any) => {
        if (res?.error?.id === 401.1) {
          this.cleanStorageAdmin();
        } else if (res?.error?.id === 401.2) {
          const refreshToken = localStorage.getItem("adminRefreshToken");
          return this.useRefreshTokenAdmin({ request, data, refreshToken });
        } else if (res?.error) return res;
        else {
          if (res.accessToken) localStorage.setItem("adminAccessToken", res.accessToken);
          if (res.refreshToken) localStorage.setItem("adminRefreshToken", res.refreshToken);
          return res;
        }
      })
      .catch(console.error);
  };

  public cleanStorageAdmin = () => {
    localStorage.removeItem("adminAccessToken");
    localStorage.removeItem("adminRefreshToken");
    this.dispatch({ type: "SET_ADMIN", value: { isAuthenticated: false } });
  };

  protected storeInRedux = (value: any) => {
    this.dispatch({ type: this.reducerType, value });
  };

  protected getPicData = (data: { query: any }, objectImage: File) => {
    const url = URL.createObjectURL(objectImage);
    const formData = new FormData();
    formData.append("file", objectImage);
    return { query: { ...data.query }, body: formData, json: false, url };
  };
}
