import {
  GetNbrUnread,
  InviteVolunteersToMission,
  MarkAsRead,
  Notification as NotificationType,
} from "api-neo/Notification/interfaces";
import { notificationApi } from "api-neo/Notification/index";
import Daddy from "./Daddy";
import { useDispatch, useSelector } from "react-redux";
import { GetPage } from "api-neo/Notification/interfaces";
import { AnyAction } from "redux";

/*
  This class does not have to change from a notification to another
  since these request always exists on neo notifications
*/

type reduxType = { data: any[]; nbr: number | null };
const initialState = { data: [], nbr: null };

export class Notification extends Daddy {
  private static instance: Notification;
  public static api = notificationApi;

  public r: reduxType = initialState;
  public static reducerType = "SET_NOTIFICATION";

  public static reducer = (state: reduxType, { type, value }: AnyAction) =>
    type === "SET_NOTIFICATION" ? { ...state, ...value } : state || initialState;

  public constructor(session: reduxType, dispatch: any) {
    super(Notification.reducerType, dispatch);
    if (!Notification.instance) Notification.instance = this;
    Notification.instance.r = session;
    return Notification.instance;
  }

  public reset = () => {
    this.storeInRedux({ data: [], conversation: null });
  };

  public getPage = (data: GetPage) =>
    this.fetch(Notification.api.GetPage, { ...data, token: true, org: true });

  public handleNewNotification = (data: NotificationType) => {
    this.storeInRedux({ data: [data, ...this.r.data], nbr: (this.r.nbr || 0) + 1 });
  };

  public getNbrUnread = (data: GetNbrUnread) => {
    this.fetch(Notification.api.GetNbrUnread, { ...data, token: true, org: true }).then(
      (res: any) => {
        if (!res.error) this.storeInRedux({ nbr: res.nbr });
        return res;
      }
    );
  };

  public markAsRead = (data: MarkAsRead) => {
    this.storeInRedux({ nbr: (this.r.nbr || 0) - 1 });
    this.fetch(Notification.api.MarkAsRead, { ...data, token: true, org: true }).then(
      (res: any) => {
        if (!res.error) {
          (this.r.data.find((n) => n._id === res.notification._id) || {}).read = true;
          this.storeInRedux({ data: [...this.r.data] });
        }
      }
    );
  };

  public inviteVolunteersToMission = (data: InviteVolunteersToMission) =>
    this.fetch(Notification.api.InviteVolunteersToMission, { ...data, token: true, org: true });
}

export const useNotification = () =>
  new Notification(
    useSelector((state: any) => state.notification),
    useDispatch()
  );
