import * as Joi from "libraries/joi";

class UserValidator {
 constructor() {}
  public UpdateMeDataAvailability = Joi.object({
    startDate: Joi.date().required(),
    endDate: Joi.date().required(),
  });

  public UpdateMeDataLocation = Joi.object({
    name: Joi.string().min(0).max(5000).required(),
    coordinates: Joi.array().items(Joi.number()).min(2).max(2),
  });

  public UpdateMeDataEmailPreferences = Joi.object({
    eventTomorrow: Joi.boolean(),
    pendingApplication: Joi.boolean(),
    applicationAccepted: Joi.boolean(),
    receivedPoints: Joi.boolean(),
    invitationToMission: Joi.boolean(),
    missionEnded: Joi.boolean(),
  });

  public AddDeviceTokenParam = Joi.object({
    deviceToken: Joi.string().allow(...[""]).min(0).max(5000).required(),
  });

  public SignupParam = Joi.object({
    code: Joi.number().integer().min(100000).max(999999).required(),
  });

  public SignupData = Joi.object({
    email: Joi.string().email().allow(...[""]).min(0).max(5000).required(),
    password: Joi.string().min(6).max(100).required(),
    firstName: Joi.string().min(1).max(100).required(),
    lastName: Joi.string().min(1).max(100).required(),
    volunteer: Joi.boolean(),
  });

  public UpdateByAdminParam = Joi.object({
    _id: Joi.objectId().required(),
  });

  public UpdateByAdminData = Joi.object({
    active: Joi.boolean(),
    projectPoints: Joi.number().integer().min(0).max(5000),
  });

  public UpdateMeData = Joi.object({
    description: Joi.string().allow(...[""]).min(0).max(1500),
    causes: Joi.array().items(Joi.string().valid(...["Santé et bien-être","Sanitaires et eau potable","Faune terrestre","Production et consommation responsable","Éducation","Énergie propre et accessible","Villes et communautés durables","Vie sous-marine","Industrie, innovation et infrastructure","Égalité des genres","Partenariats pour les buts","Réduction des inégalités","Faim dans le monde","Paix, justice et institutions fortes","Travail décent et croissance économique","Climat","Lutte contre la pauvreté"]).allow(...[""])).min(0).max(3),
    availability: Joi.array().items(this.UpdateMeDataAvailability).min(0).max(5000),
    expertises: Joi.array().items(Joi.string().valid(...["No expertise","Craft","Arts - Cultural","Sales - Marketing","Communication - Advertising","Accounting - Cash Management","Direction - Management","Documentation - Translation","Education","IT - Web","Legal","Logistic - Handling","Media - Design","Industrial Production","Human Resource","Health","Reception","People care","Social","Sport","Other"]).allow(...[""])).min(0).max(10),
    firstName: Joi.string().min(1).max(100),
    lastName: Joi.string().min(1).max(100),
    location: this.UpdateMeDataLocation,
    volunteer: Joi.boolean(),
    emailPreferences: this.UpdateMeDataEmailPreferences,
    language: Joi.string().valid(...["en","fr","nl"]).allow(...[""]).min(0).max(5000),
    drivingLicenseAndCar: Joi.boolean(),
  });

  public GetPageParam = Joi.object({
    text: Joi.string().allow(...[""]).min(0).max(5000),
    expertises: Joi.array().items(Joi.string().valid(...["No expertise","Craft","Arts - Cultural","Sales - Marketing","Communication - Advertising","Accounting - Cash Management","Direction - Management","Documentation - Translation","Education","IT - Web","Legal","Logistic - Handling","Media - Design","Industrial Production","Human Resource","Health","Reception","People care","Social","Sport","Other"]).allow(...[""])).min(0).max(10),
    closeTo: Joi.array().items(Joi.number()).min(0).max(5000),
    availableDays: Joi.array().items(Joi.date()).min(0).max(5000),
    notRelatedToThisMission: Joi.objectId(),
    drivingLicenseAndCar: Joi.boolean(),
    page: Joi.number().integer().min(0).max(5000),
    perPage: Joi.number().integer().min(0).max(5000),
  });

  public GetPageByAdminParam = Joi.object({
    active: Joi.boolean(),
    volunteer: Joi.boolean(),
    text: Joi.string().allow(...[""]).min(0).max(5000),
    page: Joi.number().integer().min(0).max(5000),
    perPage: Joi.number().integer().min(0).max(5000),
  });

  public GetPageMyVolunteersParam = Joi.object({
    page: Joi.number().integer().min(0).max(5000),
    perPage: Joi.number().integer().min(0).max(5000),
  });

  public GetByAdminParam = Joi.object({
    _id: Joi.objectId().required(),
  });

  public GetBySlugParam = Joi.object({
    slug: Joi.string().allow(...[""]).min(0).max(5000).required(),
  });

  public GetConfirmationEmailData = Joi.object({
    email: Joi.string().email().allow(...[""]).min(0).max(5000).required(),
  });

  public IsEmailInUseParam = Joi.object({
    email: Joi.string().email().allow(...[""]).min(0).max(5000).required(),
  });

  public SigninData = Joi.object({
    email: Joi.string().email().allow(...[""]).min(0).max(5000).required(),
    password: Joi.string().min(6).max(100).required(),
  });

  public RefreshTokenData = Joi.object({
    refreshToken: Joi.string().allow(...[""]).min(0).max(5000).required(),
  });

  public LogoutParam = Joi.object({
    all: Joi.boolean(),
    refreshToken: Joi.string().allow(...[""]).min(0).max(5000).required(),
  });

  public ChangePasswordData = Joi.object({
    oldPassword: Joi.string().min(6).max(100).required(),
    newPassword: Joi.string().min(6).max(100).required(),
  });

  public GetAccessTokenWithCodeParam = Joi.object({
    email: Joi.string().email().allow(...[""]).min(0).max(5000).required(),
    code: Joi.number().integer().min(100000).max(999999).required(),
  });

  public ChangeForgottenPasswordParam = Joi.object({
    code: Joi.number().integer().min(100000).max(999999).required(),
  });

  public ChangeForgottenPasswordData = Joi.object({
    email: Joi.string().email().allow(...[""]).min(0).max(5000).required(),
    newPassword: Joi.string().min(6).max(100).required(),
  });

  public DeleteMeParam = Joi.object({
    password: Joi.string().min(6).max(100).required(),
  });

}

export const userValidator = new UserValidator()