import { associationApi } from "api-neo/Association/index";
import Daddy from "./Daddy";
import { useDispatch, useSelector } from "react-redux";
import {
  Create,
  DeleteCoverPhoto,
  DeleteMyAssociation,
  DeleteProfilePhoto,
  GetById,
  GetBySlug,
  GetMyAsso,
  GetPage,
  GetPageByAdmin,
  UpdateByAdmin,
  UpdateCoverPhoto,
  UpdateMine,
  UpdateProfilePhoto,
} from "api-neo/Association/interfaces";
import { Association as AssociationType } from "api-neo/Association/interfaces";
import { User as UserType } from "api-neo/User/interfaces";
import { AnyAction } from "redux";

/*
  This class does not have to change from a association to another
  since these request always exists on neo associations
*/

type reduxType = { me: null | AssociationType; isAuthenticated: boolean | null; data: any[] };

const initialState = { me: null, isAuthenticated: null, data: [] };

export class Association extends Daddy {
  private static instance: Association;
  public static api = associationApi;

  public r: reduxType = initialState;
  public static reducerType = "SET_ASSOCIATION";

  public static reducer = (state: reduxType, { type, value }: AnyAction) =>
    type === "SET_ASSOCIATION" ? { ...state, ...value } : state || initialState;

  public constructor(session: Association["r"], dispatch: any) {
    super(Association.reducerType, dispatch);
    if (!Association.instance) Association.instance = this;
    Association.instance.r = session;
    return Association.instance;
  }

  public reset = () => {
    this.storeInRedux({ me: null });
  };

  public getBySlug = (data: GetBySlug) => this.fetch(Association.api.GetBySlug, data);

  public getMyAsso = (data: GetMyAsso) =>
    this.fetch(Association.api.GetMyAsso, { ...data, token: true });

  public getMe = (data: GetById) => {
    this.fetch(Association.api.GetById, { ...data, token: true, org: true }).then((res: any) =>
      this.storeInRedux({
        me: res.association,
      })
    );
  };

  public getPage = (data: GetPage) => this.fetch(Association.api.GetPage, { ...data, token: true });

  public getPageByAdmin = (data: GetPageByAdmin) =>
    this.fetch(Association.api.GetPageByAdmin, { ...data, token: true });

  public create = (data: Create) => this.fetch(Association.api.Create, { ...data, token: true });

  public deleteCoverPhoto = (data: DeleteCoverPhoto) =>
    this.fetch(Association.api.DeleteCoverPhoto, { ...data, token: true, org: true }).then(
      (res: any) => {
        this.storeInRedux({ me: { ...this.r.me, coverPhoto: "" } });
        return res;
      }
    );

  public deleteProfilePhoto = (data: DeleteProfilePhoto) =>
    this.fetch(Association.api.DeleteProfilePhoto, { ...data, token: true, org: true }).then(
      (res: any) => {
        this.storeInRedux({ me: { ...this.r.me, profilePhoto: "" } });
        return res;
      }
    );

  public update = (data: UpdateMine) =>
    this.fetch(Association.api.UpdateMine, { ...data, token: true, org: true }).then((res: any) => {
      this.storeInRedux({
        me: { ...res.association, admins: this.r.me?.admins || [] },
      });
      return res;
    });

  public updateByAdmin = (data: UpdateByAdmin) =>
    this.fetch(Association.api.UpdateByAdmin, { ...data, token: true });

  public addAdmin = (admin: UserType) => {
    this.fetch(Association.api.AddAdmin, {
      data: { admin: admin._id },
      token: true,
      org: true,
    }).then((res: any) =>
      this.storeInRedux({
        me: {
          ...res.association,
          admins: [...(this.r.me?.admins || []), admin],
        },
      })
    );
  };

  public updateProfilePhoto = (data: UpdateProfilePhoto, objectImage: File) => {
    const { url, ...picData } = this.getPicData(data, objectImage);
    return this.fetch(Association.api.UpdateProfilePhoto, { ...picData, token: true, org: true })
      .then((res: any) => {
        if (!res.error) this.storeInRedux({ me: { ...this.r.me, profilePhoto: url } });
        return res;
      })
      .catch(console.error);
  };

  public updateCoverPhoto = (data: UpdateCoverPhoto, objectImage: File) => {
    const { url, ...picData } = this.getPicData(data, objectImage);
    return this.fetch(Association.api.UpdateCoverPhoto, { ...picData, token: true, org: true })
      .then((res: any) => {
        if (!res.error) this.storeInRedux({ me: { ...this.r.me, coverPhoto: url } });
        return res;
      })
      .catch(console.error);
  };

  public deleteByAdmin = (data: DeleteMyAssociation) =>
    this.fetch(Association.api.DeleteMyAssociation, { ...data, token: true });

  public delete = (data: DeleteMyAssociation, resetUser: () => void) =>
    this.fetch(Association.api.DeleteMyAssociation, { ...data, token: true, org: true }).then(
      (res: any) => {
        resetUser();
        this.storeInRedux({ me: null });
        return res;
      }
    );
}

export const useAssociation = () =>
  new Association(
    useSelector((state: any) => state.association),
    useDispatch()
  );
