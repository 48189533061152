export const StartEllipsis = ({ pages, isTotalPageShort }: any): JSX.Element =>
  !isTotalPageShort && pages[0] !== 1 ? (
    <li className="grid col-span-1 pb-1 place-items-center ">
      <p>...</p>
    </li>
  ) : (
    <></>
  );

export const EndEllipsis = ({ pages, total, isTotalPageShort }: any): JSX.Element =>
  !isTotalPageShort && pages[3] !== total - 2 ? (
    <li className="grid col-span-1 pb-1 place-items-center ">
      <p>...</p>
    </li>
  ) : (
    <></>
  );
