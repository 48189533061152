import { applicationApi } from "api-neo/Application";
import Daddy from "./Daddy";
import { useDispatch, useSelector } from "react-redux";
import {
  Accept,
  CandidateCame,
  Create,
  CreateForAWeeklySlotNotGeneratedYet,
  GetMissionsRelated,
  GetPageByAsso,
  Refuse,
  Review,
} from "api-neo/Application/interfaces";
import { dateToDay } from "libraries/dash";
import { AnyAction } from "redux";

/*
  This class does not have to change from a application to another
  since these request always exists on neo applications
*/

type ApplyMany = {
  mission: string;
  schedules: { _id: string }[];
  weeklySchedules: { _id: string; startDate: Date; endDate: Date }[];
};

type reduxType = { data: any[] };

const initialState = { data: [] };

const reducer = "SET_APPLICATION";

export class Application extends Daddy {
  private static instance: Application;
  private static api = applicationApi;

  public r: reduxType = initialState;
  public static reducerType = "SET_APPLICATION";

  public static reducer = (state: reduxType, { type, value }: AnyAction) =>
    type === "SET_APPLICATION" ? { ...state, ...value } : state || initialState;

  public constructor(session: Application["r"], dispatch: any) {
    super(Application.reducerType, dispatch);
    if (!Application.instance) Application.instance = this;
    Application.instance.r = session;
    return Application.instance;
  }

  public getPageByAsso = (data: GetPageByAsso) => this.fetch(Application.api.GetPageByAsso, { ...data, token: true });

  public getMissionRelated = (data: GetMissionsRelated) => this.fetch(Application.api.GetMissionsRelated, data);

  public reset = () => {
    this.dispatch({ type: reducer, value: { data: [], application: null } });
  };

  public create = (data: Create) => this.fetch(Application.api.Create, { ...data, token: true });

  private findWithId = (id: string) => {
    return this.r.data.find((a) => a._id === id) || {};
  };

  public candidateCame = (data: CandidateCame) =>
    this.fetch(Application.api.CandidateCame, { ...data, token: true, org: true });

  public accept = (data: Accept) => this.fetch(Application.api.Accept, { ...data, token: true, org: true });

  public refuse = (data: Refuse) => this.fetch(Application.api.Refuse, { ...data, token: true, org: true });

  public createForAWeeklySlotNotGeneratedYet = (data: CreateForAWeeklySlotNotGeneratedYet) =>
    this.fetch(Application.api.CreateForAWeeklySlotNotGeneratedYet, { ...data, token: true });

  public applyMany = ({ schedules, weeklySchedules, mission }: ApplyMany) =>
    Promise.all([
      ...weeklySchedules.map((sch) =>
        this.createForAWeeklySlotNotGeneratedYet({
          param: { mission, weeklyScheduleId: sch._id, day: dateToDay(sch.startDate) },
        })
      ),
      ...schedules.map((sch) => this.create({ data: { schedule: sch._id } })),
    ]);

  public review = (data: Review) => this.fetch(Application.api.Review, { ...data, token: true, org: true });
}

export const useApplication = () =>
  new Application(
    useSelector((state: any) => state.application),
    useDispatch()
  );
