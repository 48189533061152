const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

interface FetchInterface {
  stringify?: boolean;
  token?: string;
  path: string;
  headers?: any;
  param?: any;
  data?: any;
  body?: any;
  org?: string;
  json?: boolean;
  query?: Record<string, any>;
}

const getQueryString = (params: any): string => {
  if (!params || Object.keys(params)?.length === 0) return "";
  const parts = Object.entries(params).map(([k, v]: any) => (v !== undefined ? `${k}=${v}` : ""));
  return "?" + parts.join("&");
};

export const FETCH = ({
  token,
  path = "",
  headers = {},
  data,
  param,
  query,
  body = {},
  json = true,
  org,
}: FetchInterface) => {
  const req: any = { method: "POST", headers, body };

  if (token) req.headers.Authorization = `Bearer ${token}`;

  if (json) {
    req.headers["Content-Type"] = "application/json";
    req.headers.Accept = "application/json";
    if (data) req.body.data = data;
    if (param) req.body.param = param;
    req.body = JSON.stringify(req.body);
  }

  if (!path.includes("http")) path = BACKEND_URL + path;
  path = `${path}${getQueryString({ ...query, org })}`;

  return global
    .fetch(path, req)
    .then((res) => res.json())
    .catch((error) => {
      console.error(error);
      return { error: { id: 500 } };
    });
};
