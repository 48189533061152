import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import {
  dateEq,
  fDateNum,
  getDaysOfMonth,
  getDayOf,
  getDaysOfMonthByWeek,
  getEndOfMonth,
  mergeDateMin,
} from "libraries/dash/date";

import { useEffect, useState } from "react";
import { Day } from "./Day";
import { useSchedule } from "classes/Schedule";
import { useAllPagination, usePagination } from "hooks/usePagination";
import { useMission } from "classes/Mission";
import { useUpdateEffect } from "hooks/useUpdateEffect";
import { FreeSlots } from "./FreeSlots";
import { useAdmin } from "classes/Admin";
import { ScheduleModal } from "./ScheduleModal";

export const Calendar = ({ asso }: any) => {
  const schedule = useSchedule();
  const admin = useAdmin();
  const mission = useMission();

  const [day, setDay] = useState(new Date());
  const [open, setOpen] = useState(false);

  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [groupedSchedules, setGroupedSchedules] = useState([]);

  const [free, setFree] = useState("");

  const isInMonth = (d: Date) => {
    return d.getMonth() === month;
  };

  const addMonth = () => {
    if (month === 11) setYear(year + 1);
    setMonth((month + 1) % 12);
  };

  useUpdateEffect(() => {
    setFilters({
      param: {
        fromDate: new Date(year, month, 1),
        toDate: getEndOfMonth(new Date(year, month, 1)),
      },
    });
  }, [year, month]);

  const removeMonth = () => {
    if (month === 0) setYear(year - 1);
    setMonth((month + 11) % 12);
  };

  const { pageData, setFilters } = useAllPagination(schedule.getPageCalendar, {
    dependencies: [admin.r.isAuthenticated, month, asso?._id],
    initialFilters: {
      param: {
        fromDate: new Date(year, month, 1),
        toDate: getEndOfMonth(new Date(year, month, 1)),
      },
    },
    noFetch: !admin.r.isAuthenticated || !asso?._id,
    pageProps: { param: { perPage: 1000 }, org: asso?._id },
  });
  const { pageData: templatesPageData } = useAllPagination(mission.getPageTemplates, {
    dependencies: [admin.r.isAuthenticated, asso?._id],
    noFetch: !admin.r.isAuthenticated || !asso?._id,
    pageProps: { param: { perPage: 1000 }, org: asso?._id },
  });

  const generateSchedulesOfDay = (date: Date, weeklySchedules: any, mission: any, startDate: Date, endDate: Date) => {
    if (!weeklySchedules) return [];
    if (date.getTime() < startDate.getTime()) return [];
    if (date.getTime() > endDate.getTime()) return [];

    return weeklySchedules
      .filter((sch: any) => sch.day === date.getDay())
      .filter((sch: any) => !sch.exceptions.includes(fDateNum(date)))
      .filter(
        (wsch: any) =>
          !schedules.some(
            (sch: any) =>
              dateEq(new Date(sch.startDate), mergeDateMin(date, wsch.startHour)) &&
              dateEq(new Date(sch.endDate), mergeDateMin(date, wsch.endHour))
          )
      )
      .map((wsch: any) => ({
        startDate: mergeDateMin(date, wsch.startHour),
        endDate: mergeDateMin(date, wsch.endHour),
        capacity: wsch.capacity,
        nbrApplicationsAccepted: 0,
        nbrApplicationsPending: 0,
        mission: mission,
        nbrApplicationsAcceptedAndCameNull: 0,
        _id: wsch._id,
        type: "weekly-template",
      }));
  };

  const schedules = pageData.data;
  const weeklySchedules = templatesPageData.data;

  const sortByDate = (a: any, b: any) => new Date(a.startDate).getTime() > new Date(b.startDate).getTime();

  const groupByDay = () => {
    const grouped: any = {};
    schedules.forEach((a) => {
      const day = getDayOf(a.startDate).getTime();
      grouped[day] = [...(grouped[day] || []), a];
    });

    const days = getDaysOfMonth(year, month);
    const ws = days.map((d) =>
      weeklySchedules.map((m) =>
        generateSchedulesOfDay(
          d,
          m.weeklySchedules,
          { ...m, weeklySchedules: undefined },
          new Date(m.startDate),
          new Date(m.endDate)
        )
      )
    );
    days.forEach((d: any) => {
      grouped[d.getTime()] = [...(grouped[d.getTime()] || []), ...ws[d.getDate() - 1].flat()];
    });

    days.forEach((d: any) => {
      (grouped[d.getTime()] || []).sort(sortByDate);
    });
    setGroupedSchedules(grouped);
  };

  const { pageData: pageDataFree } = useAllPagination(schedule.getPageCalendar, {
    pageProps: {
      param: { type: "free-slot", perPage: 1000 },
      org: asso?._id,
    },
    dependencies: [admin.r.isAuthenticated],
    noFetch: !admin.r.isAuthenticated || !asso?._id,
  });

  // useEffect(groupByDay, [pageData, templatesPageData]);

  return (
    <>
      <ScheduleModal
        open={open || free}
        onClose={() => {
          if (free) setFree("");
          else setOpen(false);
        }}
        day={day}
        schedules={free ? pageDataFree.data.filter((s) => s.mission?._id === free) : groupedSchedules[day.getTime()]}
        free={free}
        asso={asso}
      />
      <div className="">
        <div className="flex flex-row items-center " style={{ justifyContent: "space-between" }}>
          <div className="flex p-1 rounded-full cursor-pointer" onClick={removeMonth}>
            <ChevronLeftIcon className="w-8 h-8 p-1 rounded-md hover:bg-gray-50" />
          </div>
          <div className="flex m-1 text-xl font-bold uppercase">
            {new Date(year, month).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "long",
            })}
          </div>
          <div className="flex p-1 rounded-full cursor-pointer" onClick={addMonth}>
            <ChevronRightIcon className="w-8 h-8 p-1 rounded-md hover:bg-gray-50" />
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="flex w-full mb-3">
            {["DI", "LU", "MA", "ME", "JE", "VE", "SA"].map((d) => {
              return (
                <div className="flex items-center flex-1">
                  <p className="w-full mx-auto text-sm font-semibold text-center uppercase" style={{ minWidth: 132 }}>
                    {d}
                  </p>
                </div>
              );
            })}
          </div>
          {getDaysOfMonthByWeek(year, month).map((week) => {
            return (
              <div className="flex w-full mx-4">
                {week.map((d) => (
                  <Day
                    d={d}
                    schedules={groupedSchedules[d.getTime()]}
                    isInMonth={isInMonth(d)}
                    clickOnDate={() => {
                      setDay(d);
                      setOpen(true);
                    }}
                    hoverOnDate={() => {}}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </div>
      <FreeSlots free={free} setFree={setFree} pageData={pageDataFree} />
    </>
  );
};
