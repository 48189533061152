import { FETCH } from "classes/Fetch";
import  { GetPageByAsso , GetPageByVol , GetMissionsRelated , Create , CreateForAWeeklySlotNotGeneratedYet , Accept , CancelByAsso , Refuse , CandidateCame , MarkMyAbsence , Review } from "./interfaces"

class ApplicationApi { 
  private readonly r: string = "application";

  constructor() {}

  // Auhorization: [{"t":"Association","org":true},{"t":"Admin"}]
  public GetPageByAsso = (data: GetPageByAsso) => {
    return FETCH({
      path: `${this.r}/get-page-by-asso`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"}]
  public GetPageByVol = (data: GetPageByVol) => {
    return FETCH({
      path: `${this.r}/get-page-by-vol`,
      ...data,
    });
  };

  // Auhorization: "all"
  public GetMissionsRelated = (data: GetMissionsRelated) => {
    return FETCH({
      path: `${this.r}/get-missions-related`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User","a":true}]
  public Create = (data: Create) => {
    return FETCH({
      path: `${this.r}/create`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"}]
  // It will create the weekly schedule, then create application on it.
  public CreateForAWeeklySlotNotGeneratedYet = (data: CreateForAWeeklySlotNotGeneratedYet) => {
    return FETCH({
      path: `${this.r}/create-for-a-weekly-slot-not-generated-yet`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public Accept = (data: Accept) => {
    return FETCH({
      path: `${this.r}/accept`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public CancelByAsso = (data: CancelByAsso) => {
    return FETCH({
      path: `${this.r}/cancel-by-asso`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public Refuse = (data: Refuse) => {
    return FETCH({
      path: `${this.r}/refuse`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  // The frontend has to block this operation before the schedule endDate.
  public CandidateCame = (data: CandidateCame) => {
    return FETCH({
      path: `${this.r}/candidate-came`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"}]
  // The frontend has to block this operation X day before the start of the schedule.
  public MarkMyAbsence = (data: MarkMyAbsence) => {
    return FETCH({
      path: `${this.r}/mark-my-absence`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  // The frontend has to block this operation before the schedule endDate.
  public Review = (data: Review) => {
    return FETCH({
      path: `${this.r}/review`,
      ...data,
    });
  }; 
}

export const applicationApi = new ApplicationApi()
