import { FETCH } from "classes/Fetch";
import  { GetPage , GetPageCalendar , CreateManyCustom , CreateFreeSlot , CreateOneWeekly , Update , UpdateOneWeeklyNotGeneratedYet , Delete , CountPerMission } from "./interfaces"

class ScheduleApi { 
  private readonly r: string = "schedule";

  constructor() {}

  public GetPage = (data: GetPage) => {
    return FETCH({
      path: `${this.r}/get-page`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true},{"t":"Admin"}]
  public GetPageCalendar = (data: GetPageCalendar) => {
    return FETCH({
      path: `${this.r}/get-page-calendar`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  // Can be used to create special schedules, not weekly ones.
  public CreateManyCustom = (data: CreateManyCustom) => {
    return FETCH({
      path: `${this.r}/create-many-custom`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  // Used to create a free slot, you can only create one per mission.
  public CreateFreeSlot = (data: CreateFreeSlot) => {
    return FETCH({
      path: `${this.r}/create-free-slot`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"}]
  public CreateOneWeekly = (data: CreateOneWeekly) => {
    return FETCH({
      path: `${this.r}/create-one-weekly`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public Update = (data: Update) => {
    return FETCH({
      path: `${this.r}/update`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  // It will generate the weekly schedule, then update it.
  public UpdateOneWeeklyNotGeneratedYet = (data: UpdateOneWeeklyNotGeneratedYet) => {
    return FETCH({
      path: `${this.r}/update-one-weekly-not-generated-yet`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  // If you delete a weekly schedule, do not forget to add an exception in the mission's weekly schedule template.
  public Delete = (data: Delete) => {
    return FETCH({
      path: `${this.r}/delete`,
      ...data,
    });
  };

  public CountPerMission = (data: CountPerMission) => {
    return FETCH({
      path: `${this.r}/count-per-mission`,
      ...data,
    });
  }; 
}

export const scheduleApi = new ScheduleApi()
