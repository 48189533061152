import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";
import { isPassed, uniq } from "libraries/dash";
import { fDate, fTime } from "libraries/dash/date";
import { useApplication } from "classes/Application";
import { useMission } from "classes/Mission";
import { useAllPagination, usePagination } from "hooks/usePagination";
import { Overlay } from "components/utils/Overlay";
import { Link } from "react-router-dom";

export const ScheduleModal = ({ open, day, schedules, onClose, free, asso }: any) => {
  return (
    <Overlay open={!!open} setOpen={onClose}>
      <div className="bg-white">
        <Applications day={day} onClose={() => onClose(false)} schedules={schedules} free={free} asso={asso} />
      </div>
    </Overlay>
  );
};

type State = "pending" | "accepted" | "refused" | "cancel_by_asso" | "cancel_by_vol";

export const Applications = ({ onClose, day, schedules, free, asso }: any) => {
  const beginDay = new Date(day);
  beginDay.setHours(0, 0, 0, 0);
  const endDay = new Date(day);
  endDay.setHours(23, 59, 59, 999);

  const application = useApplication();
  const mission = useMission();

  const { pageData } = useAllPagination(application.getPageByAsso, {
    pageProps: {
      org: asso?._id,
      param: {
        scheduleType: free ? "free-slot" : undefined,
        mission: free || undefined,
        from: free ? undefined : beginDay,
        to: free ? undefined : endDay,
      },
    },
    dependencies: [mission.r.mission?._id, day, free],
  });

  const missions = uniq([...pageData.data.map((a) => a.mission), ...(schedules || []).map((s: any) => s.mission)]);

  const schedulesByMission: any = {};
  schedules?.forEach(
    (s: any) => (schedulesByMission[s.mission._id] = uniq([...(schedulesByMission[s.mission._id] || []), s]))
  );
  return (
    <>
      <div
        className="flex flex-col w-full p-4 bg-white border-2 rounded-lg max-lg::overflow-hidden border-gray-50 shadow-test max-md:shadow-none sm:px-6"
        style={{ minHeight: "500px" }}
      >
        <div className="w-full md:!max-w-full ">
          <div className="flex w-full max-md:justify-between ">
            {!free ? (
              <h2 className="mb-2 text-2xl font-bold text-gray-900 uppercase">
                {fDate(day, { weekday: "long" }, "fr-FR").slice(0, 3)} {fDate(day, { day: "numeric" }, "fr-FR")}{" "}
                {fDate(day, { month: "long" }, "fr-FR").slice(0, 3)}
              </h2>
            ) : (
              <h2 className="mb-2 text-2xl font-bold text-gray-900">Créneau libre</h2>
            )}
            <XCircleIcon className="ml-auto w-9 h-9 icon" onClick={onClose} />
          </div>
          <div className="flex pb-5 max-lg:overflow-x-auto">
            <div className="flex flex-col w-full">
              {missions.map((m: any) =>
                schedulesByMission[m._id]?.map((s: any) => (
                  <div className="w-full pb-4">
                    <div className="flex items-center w-full">
                      <p>
                        <span className="text-lg font-bold text-gray-900">{m.name}</span>
                        {!free && (
                          <span className="ml-3 text-sm text-gray-700">
                            {fTime(s.startDate, { hour: "numeric", minute: "numeric" }, "fr-FR")}-
                            {fTime(s.endDate, { hour: "numeric", minute: "numeric" }, "fr-FR")}
                          </span>
                        )}
                      </p>
                      <p className="ml-auto font-bold">
                        {s?.applications?.accepted || 0}/{s.capacity}
                      </p>
                    </div>
                    <TableHeader />
                    {pageData.data
                      .filter((a) => a.schedule._id === s._id)
                      .map((el) => (
                        <TableRow el={el} />
                      ))}
                    {pageData.data.filter((a) => a.schedule._id === s._id).length === 0 && (
                      <div className="items-center justify-center w-full px-8 py-3 mt-2 text-sm text-gray-700 rounded-lg bg-gray-50">
                        <p className="mx-auto text-center">Pas de candidatures</p>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TableHeader = () => {
  return (
    <div className="grid items-center w-full grid-cols-12 px-1 pb-1 border-b-2 border-gray-100">
      <div className="flex items-center col-span-4">Nom</div>
      <div className="flex items-center col-span-3">Statut</div>
      <div className="flex items-center col-span-2">Présence</div>
      <div className="flex items-center col-span-2">Avis</div>
      <div className="flex items-center col-span-1"></div>
    </div>
  );
};

const TableRow = ({ el }: any) => {
  return (
    <>
      <div className="grid items-center w-full grid-cols-12 px-1 rounded-lg hover:bg-gray-50">
        <div className="flex items-center col-span-4">
          <img
            src={el?.candidate?.profilePhoto || "/default/volunteer-cover.svg"}
            className="object-cover w-8 h-8 rounded-full"
            style={{ minWidth: 32, minHeight: 32 }}
          />
          <Link to={`/users/${el.candidate.slug}`}>
            <p className="ml-3 text-gray-700 cursor-pointer hover:underline">
              {el.candidate.firstName} {el.candidate.lastName}
            </p>
          </Link>
        </div>
        <div className="flex items-center col-span-3 py-3">
          <Badge state={el.state} text={el.state} />
        </div>
        <div className="flex col-span-2">
          {el.came === null ? null : el.came ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red" />
          )}
        </div>
        <div className="flex items-center col-span-2">
          {(!el.schedule.endDate || isPassed(el.schedule.endDate)) &&
            el.state === "accepted" &&
            el.came !== null &&
            el.stars !== null && <RatingIcon value={el.stars} />}
        </div>
      </div>
    </>
  );
};

type RatingIcon = {
  variant?: "input" | "visual";
  setValue?: (e: any) => void;
  value?: any;
  onClick?: () => void;
};

export const RatingIcon = ({ onClick, value }: RatingIcon): JSX.Element => {
  return (
    <div onClick={onClick} className="flex cursor-pointer">
      {new Array(5).fill(0).map((_: any, i: number) => (
        <StarIcon className={`w-5 h-5 ${value <= i ? "text-gray-100" : "text-yellow-400"}`} />
      ))}
    </div>
  );
};

const Badge = ({ text, state }: { text: string; state: State }) => {
  const classNames = {
    accepted: "bg-green-50 text-green-500",
    refused: "bg-redLight text-red",
    pending: "bg-yellow-50 text-yellow-500",
    cancel_by_asso: "bg-gray-50 text-gray-500",
    cancel_by_vol: "bg-gray-50 text-gray-500",
  };
  return <div className={`flex px-2 py-1 text-xs uppercase  rounded-lg ${classNames[state]}`}>{text}</div>;
};
