import { datesAreOnSameDay } from "libraries/dash";
import { fTime, isPassed } from "libraries/dash/date";
export const Day = ({ d, schedules, clickOnDate }: any) => {
  const isToday = datesAreOnSameDay(d, new Date());
  const needNotes = schedules?.some(
    (s: any, i: number) =>
      isPassed(s.startDate) &&
      i > 2 &&
      s.applications?.accepted > s.applications?.acceptedAndNotCameInfo
  );
  const needAccept = schedules?.some(
    (s: any, i: number) => !isPassed(s.startDate) && i > 2 && s.applications?.pending
  );

  return (
    <div
      className={`flex flex-col h-44 flex-1 p-2 border ${
        isToday ? "border-teal-500" : "border-gray-50"
      }  ${schedules?.length > 0 ? "cursor-pointer" : ""}`}
      style={{ minWidth: 132 }}
      onClick={() => {
        if (schedules?.length > 0) clickOnDate();
      }}
    >
      <p className={`text-xs font-bold ${isToday ? "text-teal-500" : "text-gray-500"}`}>
        {isToday ? "Aujourd'hui" : d.getDate()}
      </p>
      {schedules?.slice(0, 3)?.map((s: any) => (
        <Schedule schedule={s} />
      ))}
      {schedules?.[3] && (
        <OtherSchedule nb={schedules.length - 3} needNotes={needNotes} needAccept={needAccept} />
      )}
    </div>
  );
};

const OtherSchedule = ({ nb, needAccept, needNotes }: any) => {
  return (
    <div className="flex items-center w-full">
      <p className="px-2 py-1 text-xs text-gray-700 truncate bg-gray-100 rounded-md">
        +{nb} autre.s créneau.x
      </p>
      <div className="flex ml-auto">
        {needNotes && <div className="w-2 h-2 ml-1 bg-green-500 rounded-full" />}
        {needAccept && <div className="w-2 h-2 ml-1 rounded-full bg-amber-500" />}
      </div>
    </div>
  );
};

const Schedule = ({ schedule }: any) => {
  const apps = schedule?.applications || {};
  return (
    <div className="flex flex-col py-1">
      <div className="flex w-full">
        <p className="text-xs font-semibold truncate">{schedule.mission?.name}</p>
        <p className="ml-auto text-xs font-semibold">
          {apps?.accepted || 0}/{schedule.capacity}
        </p>
      </div>
      <div className="flex w-full">
        <p className="text-xs text-gray-400">
          {fTime(schedule.startDate, { hour: "numeric", minute: "numeric" })}
          {" -  "}
          {fTime(schedule.endDate, { hour: "numeric", minute: "numeric" })}
        </p>
        {isPassed(schedule.startDate) ? (
          <div className="flex items-center h-full ml-auto">
            {(apps?.accepted || 0) - (apps?.acceptedAndNotCameInfo || 0) > 3 && (
              <p className="text-xs text-green-500 ">+</p>
            )}
            {Array(Math.min((apps?.accepted || 0) - (apps?.acceptedAndNotCameInfo || 0), 3))
              .fill(0)
              .map(() => (
                <div className="w-2 h-2 ml-1 bg-green-500 rounded-full" />
              ))}
          </div>
        ) : (
          <div className="flex items-center h-full ml-auto">
            {apps?.pending > 3 && <p className="text-xs text-amber-500">+</p>}
            {Array(Math.min(apps?.pending || 0, 3))
              .fill(0)
              .map(() => (
                <div className="w-2 h-2 ml-1 rounded-full bg-amber-500" />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
