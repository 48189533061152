import { reviewApi } from "api-neo/Review/index";
import Daddy from "./Daddy";
import { useDispatch, useSelector } from "react-redux";
import { Create, GetPageMine, GetPagePublic, Pin } from "api-neo/Review/interfaces";
import { AnyAction } from "redux";

/*
  This class does not have to change from a review to another
  since these request always exists on neo reviews
*/

type reduxType = { data: any[] };
const initialState = { data: [] };

export class Review extends Daddy {
  private static instance: Review;
  private static api = reviewApi;

  public r: reduxType = initialState;
  public static reducerType = "SET_REVIEW";

  public static reducer = (state: reduxType, { type, value }: AnyAction) =>
    type === "SET_REVIEW" ? { ...state, ...value } : state || initialState;

  public constructor(session: reduxType, dispatch: any) {
    super(Review.reducerType, dispatch);
    if (!Review.instance) Review.instance = this;
    Review.instance.r = session;
    return Review.instance;
  }

  public create = (data: Create) => this.fetch(Review.api.Create, data);

  public getPageMine = (data: GetPageMine) =>
    this.fetch(Review.api.GetPageMine, { ...data, token: true });

  public getPagePublic = (data: GetPagePublic) => this.fetch(Review.api.GetPagePublic, data);

  public pin = (data: Pin) =>
    this.fetch(Review.api.Pin, { ...data, token: true, org: true }).then((res: any) => {
      if (!res.error) {
        return data.data.pinned;
      } else return !data.data.pinned;
    });
}

export const useReview = () =>
  new Review(
    useSelector((state: any) => state.review),
    useDispatch()
  );
