import { ClockIcon, DocumentIcon, UserIcon } from "@heroicons/react/solid";
import { useAssociation } from "classes/Association";
import { useProject } from "classes/Project";
import { useUser } from "classes/User";
import { usePagination } from "hooks/usePagination";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { Transaction, useTransaction } from "classes/Transaction";
import { GetPageStatistics } from "api-neo/Transaction/interfaces";
import { getDatesBetween } from "libraries/dash";
import { useEffect, useState } from "react";
import { getDayOfYear, getEndOfMonth, getEndOfYear } from "libraries/dash/date";
import { Select } from "components/forms/Select";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options1 = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Achats",
    },
  },
};
export const options2 = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Crédits",
    },
  },
};

type GroupByType = GetPageStatistics["param"]["groupBy"];

const range = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx);
};

type monthData = {
  _id: { year: number; month: number };
  totalAmount: number;
  totalPrice: number;
}[];

type dayData = { _id: { year: number; day: number }; totalAmount: number; totalPrice: number }[];

const getMonths = (data: monthData, from?: Date, to: Date = new Date()) => {
  const beginYear = from?.getFullYear() || Math.min(...data.map((d) => d._id.year), 2021);
  const beginMonth = from
    ? from.getMonth()
    : Math.min(...data.filter((d) => d._id.year === beginYear).map((d) => d._id.month), 0);
  const endYear = to.getFullYear();
  const endMonth = to.getMonth();

  const months = range(beginYear * 12 + beginMonth, endYear * 12 + endMonth);
  return months;
};

const getDays = (data: dayData, from?: Date, to: Date = new Date()) => {
  const beginYear = from?.getFullYear() || Math.min(...data.map((d) => d._id.year), 2021);
  const beginDay = from
    ? getDayOfYear(from)
    : Math.min(...data.filter((d) => d._id.year === beginYear).map((d) => d._id.day), 0);
  const days = getDatesBetween(new Date(beginYear, 0, beginDay), to);
  return days;
};

const formatMonths = (months: number[]) => {
  return months
    .map((m) => new Date(Math.floor(m / 12), m % 12, 1))
    .map((m) => m.toLocaleDateString("en", { month: "numeric", year: "numeric" }));
};

const formatDays = (months: Date[]) => {
  return months.map((m) => m.toLocaleDateString("fr", { day: "numeric", month: "numeric", year: "numeric" }));
};

const getValuesByDay = (data: dayData, tot: "totalAmount" | "totalPrice", from?: Date, to: Date = new Date()) => {
  const days = getDays(data, from, to);
  const values = days.map(
    (m) => data.find((d) => d._id.year == m.getFullYear() && d._id.day - 1 === getDayOfYear(m))?.[tot]
  );
  return values;
};

const getValuesByMonth = (data: monthData, tot: "totalAmount" | "totalPrice", from?: Date, to: Date = new Date()) => {
  const months = getMonths(data, from, to);
  const values = months.map(
    (m) => data.find((d) => d._id.year === Math.floor(m / 12) && d._id.month === m % 12)?.[tot]
  );
  return values;
};

const titles = ["Achats", "Dépenses", "Cadeaux", "Remboursements"];
const colors = ["rgb(147, 197, 253)", "rgb(253, 224, 71)", "rgb(134, 239, 172)", "rgb(252, 165, 165)"];

const toGraphData = (labels: string[], ...values: (number | undefined)[][]) => {
  return {
    labels,
    datasets: values.map((value, i) => ({
      label: titles[i],
      data: value.map((v) => Transaction.conversionToNumber(v || 0)),
      borderColor: colors[i % colors.length],
      backgroundColor: colors[i % colors.length],
    })),
  };
};

type VisType = "month" | "year" | "all";

const monthOptions = Array(12)
  .fill(0)
  .map((_, i) => ({
    id: i,
    name: new Date(2022, i, 1).toLocaleDateString("fr", { month: "long" }),
  }));

const yearOptions = Array(new Date().getFullYear() - 2020)
  .fill(0)
  .map((_, i) => ({ id: 2020 + i + 1, name: (2020 + i + 1).toString() }));

export const Dashboard = () => {
  const association = useAssociation();
  const project = useProject();
  const transactions = useTransaction();
  const user = useUser();
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [vis, setVis] = useState<VisType>("month");

  const [graphDataAmount, setGraphDataAmount] = useState<ChartData<"line", number[], string>>();
  const [graphDataPrice, setGraphDataPrice] = useState<ChartData<"line", number[], string>>();

  const { pageData: pendingData } = usePagination(association.getPageByAdmin, {
    pageProps: { param: { visible: false } },
  });

  const from =
    vis === "all"
      ? undefined
      : vis === "month"
      ? new Date(selectedYear, selectedMonth, 1)
      : new Date(selectedYear, 0, 1);
  const to = !from ? new Date() : vis === "month" ? getEndOfMonth(from) : getEndOfYear(from);

  console.log(selectedMonth);
  console.log("from", from);
  console.log("to", to);
  const params: Record<VisType, { groupBy: GroupByType; perPage: number; maxDate: Date }> = {
    month: { groupBy: "Day", perPage: 31, maxDate: to },
    year: { groupBy: "Month", perPage: 12, maxDate: to },
    all: { groupBy: "Month", perPage: 100, maxDate: to },
  };

  const { pageData: expenseData } = usePagination(transactions.getPageStatistics, {
    pageProps: { param: { type: "expense", ...params[vis] } },
    dependencies: [vis],
  });

  const { pageData: purchaseData } = usePagination(transactions.getPageStatistics, {
    pageProps: { param: { type: "purchase", ...params[vis] } },
    dependencies: [vis],
  });

  const { pageData: giftsData } = usePagination(transactions.getPageStatistics, {
    pageProps: { param: { type: "gift", ...params[vis] } },
    dependencies: [vis],
  });

  const { pageData: refundData } = usePagination(transactions.getPageStatistics, {
    pageProps: { param: { type: "refund", ...params[vis] } },
    dependencies: [vis, selectedMonth, selectedYear],
  });

  useEffect(() => {
    if (params[vis].groupBy === "Day") {
      const days = getDays(
        [...purchaseData.data, ...expenseData.data, ...giftsData.data, ...refundData.data],
        from,
        to
      );
      setGraphDataAmount(
        toGraphData(
          formatDays(days),
          getValuesByDay(purchaseData.data, "totalPrice", from, to),
          getValuesByDay(expenseData.data, "totalPrice", from, to),
          getValuesByDay(giftsData.data, "totalPrice", from, to),
          getValuesByDay(refundData.data, "totalPrice", from, to)
        )
      );
      setGraphDataPrice(toGraphData(formatDays(days), getValuesByDay(purchaseData.data, "totalAmount", from, to)));
    } else {
      const months = getMonths(
        [...purchaseData.data, ...expenseData.data, ...giftsData.data, ...refundData.data],
        from,
        to
      );
      setGraphDataAmount(
        toGraphData(formatMonths(months), getValuesByMonth(purchaseData.data, "totalPrice", from, to))
      );
      setGraphDataPrice(
        toGraphData(
          formatMonths(months),
          getValuesByMonth(purchaseData.data, "totalAmount", from, to),
          getValuesByMonth(expenseData.data, "totalAmount", from, to),
          getValuesByMonth(giftsData.data, "totalAmount", from, to),
          getValuesByMonth(refundData.data, "totalAmount", from, to)
        )
      );
    }
  }, [purchaseData, expenseData, refundData, giftsData, vis, selectedMonth, selectedYear]);

  const { pageData: projectData } = usePagination(project.getPage, {});
  const { pageData: volunteerData } = usePagination(user.getPage, {});

  return (
    <div className="grid items-start w-full grid-cols-4 gap-8 p-8">
      <div className="flex flex-col col-span-1 p-4 px-6 border border-yellow-300 rounded-lg bg-yellow-50">
        <div className="flex items-end">
          <p className="text-4xl font-bold ">{pendingData.total}</p>
          <ClockIcon className="w-8 h-8 " />
        </div>
        <p className="text-sm font-bold uppercase">association désactivée</p>
      </div>
      <div className="flex flex-col col-span-1 p-4 px-6 border border-blue-300 rounded-lg bg-blue-50">
        <div className="flex items-end">
          <p className="text-4xl font-bold ">{projectData.total}</p>
          <DocumentIcon className="w-8 h-8 " />
        </div>
        <p className="text-sm font-bold uppercase">projets</p>
      </div>
      <div className="flex flex-col col-span-1 p-4 px-6 border border-green-300 rounded-lg bg-green-50">
        <div className="flex items-end">
          <p className="text-4xl font-bold ">{volunteerData.total}</p>
          <UserIcon className="w-8 h-8 " />
        </div>
        <p className="text-sm font-bold uppercase">utilisateurs</p>
      </div>
      <div className="flex flex-col col-span-4 p-8 bg-white">
        <div className="flex gap-2">
          <div
            className={`px-3 py-1 border border-gray-100 rounded-md cursor-pointer hover:bg-gray-50 ${
              vis === "year" ? "bg-gray-100" : ""
            }`}
            onClick={() => setVis("year")}
          >
            <p className="text-sm text-gray-500 ">Année</p>
          </div>
          <div
            className={`px-3 py-1 border border-gray-100 rounded-md cursor-pointer hover:bg-gray-50 ${
              vis === "month" ? "bg-gray-100" : ""
            }`}
            onClick={() => setVis("month")}
          >
            <p className="text-sm text-gray-500 ">Mois</p>
          </div>
          <div className="flex flex-row gap-2 ml-auto">
            {vis === "month" && (
              <Select selected={selectedMonth} setSelected={setSelectedMonth} options={monthOptions} />
            )}
            {vis !== "all" && <Select selected={selectedYear} setSelected={setSelectedYear} options={yearOptions} />}
          </div>
        </div>
        {graphDataPrice && <Line options={options1} data={graphDataPrice} />}
        <div className="mt-8" />
        {graphDataAmount && <Line options={options2} data={graphDataAmount} />}
      </div>
    </div>
  );
};
