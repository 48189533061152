import { useAssociation } from "classes/Association";
import { Transaction } from "classes/Transaction";
import { Pagination } from "components/ui/Pagination";
import { usePagination } from "hooks/usePagination";
import { fDate } from "libraries/dash";
import { Link } from "react-router-dom";

export const Associations = () => {
  const association = useAssociation();
  const { pageData, page, setPage } = usePagination(association.getPageByAdmin, {
    pageProps: { param: { perPage: 20 } },
  });

  return (
    <div className="flex flex-col w-full p-8 bg-gray-50">
      <h2 className="mb-2 text-2xl font-bold">Associations</h2>
      <div className="flex flex-col p-8 bg-white">
        <Header />
        {pageData.data.map((el) => (
          <TableRow el={el} />
        ))}
        <div className="mx-auto mt-4">
          <Pagination total={pageData.pages} pageNumber={page} setPageNumber={setPage} />
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div className="grid w-full grid-cols-7 pb-2 mb-2 text-sm font-bold text-gray-500 uppercase border-b-2 border-gray-100">
      <p className="col-span-3">Nom</p>
      <p className="col-span-2">Date de création</p>
      <p className="col-span-1">Statut</p>
      <p className="w-full col-span-1 text-right">Crédit</p>
    </div>
  );
};

const TableRow = ({ el }: any) => {
  return (
    <div className="grid items-center w-full grid-cols-7 mt-2">
      <div className="flex items-center col-span-3">
        <Link to={`/associations/${el.slug}`}>
          <div className="flex items-center">
            {el.profilePhoto ? (
              <img src={el.profilePhoto} className="border border-gray-200 rounded-full w-9 h-9" />
            ) : (
              <p className="flex items-center justify-center text-xs border border-gray-200 rounded-full w-9 h-9">
                {el.name?.[0]}
              </p>
            )}
            <p className="ml-2 font-semibold hover:underline">{el?.name}</p>
          </div>
        </Link>
      </div>
      <p className="col-span-2">{fDate(el.createdAt, { day: "2-digit", month: "2-digit", year: "2-digit" })}</p>
      <p className="flex col-span-1 flex-start">
        <Badge visible={el?.visible} />
      </p>
      <p className="items-end col-span-1 text-right">
        <div>{Transaction.conversion(el?.coins)}</div>
      </p>
    </div>
  );
};

const Badge = ({ visible }: any) => {
  const className = visible
    ? "text-gray-700  bg-gray-50"
    : visible === null
    ? "text-green-500 bg-green-50"
    : "text-red-500 bg-red-50";
  const text = visible ? "Visible" : visible === null ? "En attente" : "Invisible";
  return <p className={`px-3 py-1 rounded-md ${className}`}>{text}</p>;
};
