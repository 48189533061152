import { FETCH } from "classes/Fetch";
import  { GetBySlug , GetBySlugMine , GetPage , GetPageMine , Create , Update , Delete , UpdateImage , DeleteImage } from "./interfaces"

class ProjectApi { 
  private readonly r: string = "project";

  constructor() {}

  public GetBySlug = (data: GetBySlug) => {
    return FETCH({
      path: `${this.r}/get-by-slug`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true},{"t":"Admin"}]
  public GetBySlugMine = (data: GetBySlugMine) => {
    return FETCH({
      path: `${this.r}/get-by-slug-mine`,
      ...data,
    });
  };

  public GetPage = (data: GetPage) => {
    return FETCH({
      path: `${this.r}/get-page`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true},{"t":"Admin"}]
  public GetPageMine = (data: GetPageMine) => {
    return FETCH({
      path: `${this.r}/get-page-mine`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public Create = (data: Create) => {
    return FETCH({
      path: `${this.r}/create`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public Update = (data: Update) => {
    return FETCH({
      path: `${this.r}/update`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public Delete = (data: Delete) => {
    return FETCH({
      path: `${this.r}/delete`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public UpdateImage = (data: UpdateImage) => {
    return FETCH({
      path: `${this.r}/update-image`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public DeleteImage = (data: DeleteImage) => {
    return FETCH({
      path: `${this.r}/delete-image`,
      ...data,
    });
  }; 
}

export const projectApi = new ProjectApi()
