import { FETCH } from "classes/Fetch";
import  { Create , AddPeople , GetPage , GetNbrUnreadConv , FindConvWithSomeone , GetById } from "./interfaces"

class ConversationApi { 
  private readonly r: string = "conversation";

  constructor() {}

  // Auhorization: [{"t":"User","a":true},{"t":"Association","org":true}]
  public Create = (data: Create) => {
    return FETCH({
      path: `${this.r}/create`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User","a":true},{"t":"Association","org":true}]
  public AddPeople = (data: AddPeople) => {
    return FETCH({
      path: `${this.r}/add-people`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"},{"t":"Association","org":true}]
  public GetPage = (data: GetPage) => {
    return FETCH({
      path: `${this.r}/get-page`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"},{"t":"Association","org":true}]
  public GetNbrUnreadConv = (data: GetNbrUnreadConv) => {
    return FETCH({
      path: `${this.r}/get-nbr-unread-conv`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"},{"t":"Association","org":true}]
  public FindConvWithSomeone = (data: FindConvWithSomeone) => {
    return FETCH({
      path: `${this.r}/find-conv-with-someone`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"},{"t":"Association","org":true}]
  public GetById = (data: GetById) => {
    return FETCH({
      path: `${this.r}/get-by-id`,
      ...data,
    });
  }; 
}

export const conversationApi = new ConversationApi()
