import { FETCH } from "classes/Fetch";
import  { GiveByAdmin , CreateCheckoutSession , StripeWebhook , GetPage , GetPageStatistics } from "./interfaces"

class TransactionApi { 
  private readonly r: string = "transaction";

  constructor() {}

  // Auhorization: [{"t":"Admin"}]
  public GiveByAdmin = (data: GiveByAdmin) => {
    return FETCH({
      path: `${this.r}/give-by-admin`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public CreateCheckoutSession = (data: CreateCheckoutSession) => {
    return FETCH({
      path: `${this.r}/create-checkout-session`,
      ...data,
    });
  };

  public StripeWebhook = (data: StripeWebhook) => {
    return FETCH({
      path: `${this.r}/stripe-webhook`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true},{"t":"Admin"}]
  public GetPage = (data: GetPage) => {
    return FETCH({
      path: `${this.r}/get-page`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Admin"}]
  public GetPageStatistics = (data: GetPageStatistics) => {
    return FETCH({
      path: `${this.r}/get-page-statistics`,
      ...data,
    });
  }; 
}

export const transactionApi = new TransactionApi()
