import { GetByAdmin } from "./../api-neo/User/interfaces";
import { userApi } from "api-neo/User/index";
import { useDispatch, useSelector } from "react-redux";

import {
  GetConfirmationEmail,
  GetBySlug,
  GetMe,
  IsEmailInUse,
  Signin,
  Signup,
  User as UserType,
  GetPage,
  DeleteProfilePhoto,
  DeleteCoverPhoto,
  GetMyRankingPosition,
  GetRanking,
  ChangePassword,
  UpdateMe,
  UpdateProfilePhoto,
  UpdateCoverPhoto,
  DeleteMe,
  ChangeForgottenPassword,
  GetAccessTokenWithCode,
  UpdateByAdmin,
  GetPageByAdmin,
} from "api-neo/User/interfaces";
import { AnyAction } from "redux";
import { useEffect } from "react";
import Daddy from "./Daddy";
import { uniq } from "libraries/dash";
import { getDateWithDayOfWeekUTC, setHourInMinUTC } from "libraries/dash/date";
import { availability as availabilityType } from "api-neo/User/interfaces";
import { Association as AssociationType } from "api-neo/Association/interfaces";

/*
  This class does not have to change from a project to another
  since these request always exists on neo projects
*/

type reduxType = {
  me: null | (UserType & { organizations: AssociationType[] });
  isAuthenticated: boolean | null;
  data: any[];
  org: null | string;
};
const initialState = { me: null, isAuthenticated: null, data: [], org: null };

export class User extends Daddy {
  private static instance: User;
  public static api = userApi;

  public r: reduxType = initialState;
  public static reducerType = "SET_USER";

  public static reducer = (state: reduxType, { type, value }: AnyAction) =>
    type === "SET_USER" ? { ...state, ...value } : state || initialState;

  public static triedToConnect: boolean = false;
  public static isAuthenticated: boolean | null = null;

  public constructor(session: User["r"], dispatch: any) {
    super(User.reducerType, dispatch);
    if (!User.instance) User.instance = this;
    User.instance.r = session;
    return User.instance;
  }

  public setOrg = (data: { org: string | null }) => {
    if (data.org) localStorage.setItem("org", data.org);
    else localStorage.removeItem("org");
    this.storeInRedux({ org: data.org });
  };

  public addAssociation = (association: any) => {
    (this.r.me as any)?.organizations.push(association);
    this.storeInRedux({ me: { ...(this.r.me || []) } });
  };

  public getMe = (data: GetMe) =>
    this.fetch(User.api.GetMe, { ...data, token: true })
      .then((res: any) => {
        if (!res.error) {
          this.storeInRedux({ me: res.user });
          this.setIsAuthenticated(true);
        }
        return res;
      })
      .catch(console.error);

  public getBySlug = (data: GetBySlug) =>
    this.fetch(User.api.GetBySlug, data)
      .then((res: any) => {
        if (!res.error) {
          this.storeInRedux({ me: res.user });
          this.setIsAuthenticated(true);
        }
        return res;
      })
      .catch(console.error);

  public getByAdmin = (data: GetByAdmin) =>
    this.fetch(User.api.GetByAdmin, { ...data, token: true });

  public getPage = (data: GetPage) =>
    this.fetch(User.api.GetPage, { ...data, token: true }).catch(console.error);

  public getPageByAdmin = (data: GetPageByAdmin) =>
    this.fetch(User.api.GetPageByAdmin, { ...data, token: true });

  public deleteProfilePhoto = (data: DeleteProfilePhoto) =>
    this.fetch(User.api.DeleteProfilePhoto, data)
      .then((res: any) => {
        if (!res.error) {
          this.storeInRedux({ ...this.r.me, profilePhoto: "" });
        }
        return res;
      })
      .catch(console.error);

  public deleteCoverPhoto = (data: DeleteCoverPhoto) =>
    this.fetch(User.api.DeleteCoverPhoto, data)
      .then((res: any) => {
        if (!res.error) {
          this.storeInRedux({ ...this.r.me, coverPhoto: "" });
        }
        return res;
      })
      .catch(console.error);

  public scroll = (data: GetPage) => {
    this.fetch(User.api.GetPage, data)
      .then((res: any) => {
        if (!res.error) {
          const resData = { ...res };
          if (res.pageNumber) resData.data = uniq([...this.r.data, ...res.data]);
          this.storeInRedux({ ...resData });
        }
        return res;
      })
      .catch(console.error);
  };

  public getMyRankingPosition = (data: GetMyRankingPosition) =>
    this.fetch(User.api.GetMyRankingPosition, { ...data, token: true }).catch(console.error);

  public getRanking = (data: GetRanking) =>
    this.fetch(User.api.GetRanking, data).catch(console.error);

  public changePassword = (data: ChangePassword) =>
    this.fetch(User.api.ChangePassword, { ...data, token: true, org: true });

  public updateMe = (data: UpdateMe) =>
    this.fetch(User.api.UpdateMe, { ...data, token: true })
      .then((res: any) => {
        if (!res.error)
          this.storeInRedux({
            me: { ...res.user, organizations: (this.r.me as any)?.organizations },
          });
        return res;
      })
      .catch(console.error);

  public updateByAdmin = (data: UpdateByAdmin) =>
    this.fetch(User.api.UpdateByAdmin, { ...data, token: true });

  public updateProfilePhoto = (data: UpdateProfilePhoto, objectImage: File) => {
    const { url, ...picData } = this.getPicData(data, objectImage);
    return this.fetch(User.api.UpdateProfilePhoto, picData)
      .then((res: any) => {
        if (!res.error) {
          this.storeInRedux({ me: { ...this.r.me, profilePhoto: url } });
        }
      })
      .catch(console.error);
  };

  public updateCoverPhoto = (data: UpdateCoverPhoto, objectImage: File) => {
    const { url, ...picData } = this.getPicData(data, objectImage);
    return this.fetch(User.api.UpdateCoverPhoto, picData)
      .then((res: any) => {
        if (!res.error) {
          this.storeInRedux({ me: { ...this.r.me, coverPhoto: url } });
        }
        return res;
      })
      .catch(console.error);
  };

  public availabilitiesToDate = (availability?: availabilityType[]) => {
    if (!availability) return [];

    return availability.map((a) => ({
      startDate: setHourInMinUTC(getDateWithDayOfWeekUTC(a.startDay), a.startDayHour),
      endDate: setHourInMinUTC(getDateWithDayOfWeekUTC(a.endDay), a.endDayHour),
    }));
  };

  public signin = (data: Signin) =>
    User.api
      .Signin(data)
      .then((res) => {
        if (res.accessToken) localStorage.setItem("accessToken", res.accessToken);
        if (res.refreshToken) localStorage.setItem("refreshToken", res.refreshToken);
        if (!res.error) this.getMe({});
        return res;
      })
      .catch(console.error);

  public signup = (data: Signup) =>
    User.api
      .Signup(data)
      .then((res) => {
        if (res.accessToken) localStorage.setItem("accessToken", res.accessToken);
        if (res.refreshToken) localStorage.setItem("refreshToken", res.refreshToken);
        if (!res.error) this.getMe({});
        return res;
      })
      .catch(console.error);

  public isEmailInUse = (data: IsEmailInUse) => User.api.IsEmailInUse(data).catch(console.error);

  public getConfirmationEmail = (data: GetConfirmationEmail) =>
    User.api.GetConfirmationEmail(data).catch(console.error);

  public setIsAuthenticated = (isAuthenticated: boolean) => {
    this.storeInRedux({ isAuthenticated });
  };

  public logout = () => {
    const refreshToken = localStorage.getItem("refreshToken");
    this.setIsAuthenticated(false);
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("org");
    return this.fetch(User.api.Logout, { param: { refreshToken }, token: true }).catch(
      console.error
    );
  };

  public tryToConnect = () => {
    User.triedToConnect = true;
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (!accessToken || !refreshToken) {
      this.setIsAuthenticated(false);
    } else this.getMe({});
    const org = localStorage.getItem("org");
    if (org) this.storeInRedux({ org });
  };

  public deleteMe = (data: DeleteMe) =>
    this.fetch(User.api.DeleteMe, { token: true, ...data }).then(() => {
      this.setIsAuthenticated(false);
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("org");
    });

  public changeForgottenPassword = (data: ChangeForgottenPassword) =>
    this.fetch(User.api.ChangeForgottenPassword, data).catch(console.error);

  public getAccessTokenWithCode = (data: GetAccessTokenWithCode): void => {
    this.fetch(User.api.GetAccessTokenWithCode, data);
  };
}

export const useUser = () => {
  const user = new User(
    useSelector((state: any) => state.user),
    useDispatch()
  );

  return user;
};
