import { useEffect } from "react";
// Not forget global.css
export class Toast {
  private static Toaster: HTMLElement | null = null;

  public init = () => {
    const node = document?.createElement("section");
    node.classList.add("gui-toast-group");

    document?.firstElementChild?.insertBefore(node, document.body);
    Toast.Toaster = node;
  };

  private static createToast = (text: string) => {
    const node = document?.createElement("output");

    node.innerText = text;
    node.classList.add("gui-toast");
    node.setAttribute("role", "status");

    return node;
  };

  private static flipToast = (toast: any) => {
    if (!Toast.Toaster) return;
    // FIRST
    const first = Toast.Toaster?.offsetHeight;

    // add new child to change container size
    Toast.Toaster?.appendChild(toast);

    // LAST
    const last = Toast.Toaster.offsetHeight;

    // INVERT
    const invert = last - first;

    // PLAY
    const animation = Toast.Toaster.animate(
      [{ transform: `translateY(${invert}px)` }, { transform: "translateY(0)" }],
      {
        duration: 150,
        easing: "ease-out",
      }
    );
  };

  private static addToast = (toast: any) => {
    const { matches: motionOK } = window.matchMedia("(prefers-reduced-motion: no-preference)");
    if (Toast.Toaster)
      Toast.Toaster.children.length && motionOK
        ? Toast.flipToast(toast)
        : Toast.Toaster.appendChild(toast);
  };

  public static Toast = (text: string) => {
    let toast = Toast.createToast(text);
    Toast.addToast(toast);

    return new Promise(async (resolve, reject) => {
      await Promise.allSettled(toast.getAnimations().map((animation) => animation.finished));
      Toast.Toaster?.removeChild(toast);
      resolve(undefined);
    });
  };
}

export const useToast = () => {
  const toast = new Toast();
  useEffect(toast.init, []);
  return toast;
};
