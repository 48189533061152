export const FreeSlots = ({ setFree, pageData }: any) => {
  return (
    <div className="grid w-full grid-cols-5 gap-4 mt-8">
      {pageData.data.map((s: any) => {
        const apps = s.applications || {};
        const noApps = Object.keys(apps).length === 0;
        return (
          <div
            className={`flex-col col-span-1 px-4 py-3 border border-gray-200 rounded-lg ${
              noApps ? "" : "hover:bg-gray-50 cursor-pointer"
            }`}
            onClick={() => {
              if (!noApps) setFree(s.mission._id);
            }}
          >
            <div className="flex w-full">
              <p className="mr-2 font-bold truncate">{s.mission.name}</p>
              <p className="ml-auto">
                {apps.accepted || 0}/{s.capacity}
              </p>
            </div>
            <div className="flex items-center w-full">
              <p className="mr-2 text-sm text-gray-500 truncate">Créneau libre</p>
              <div className="flex ml-auto">
                <div className="flex items-center h-full ml-auto">
                  {(apps?.accepted || 0) - (apps?.acceptedAndNotCameInfo || 0) > 3 && (
                    <p className="text-xs text-green-500 ">+</p>
                  )}
                  {Array(Math.min((apps?.accepted || 0) - (apps?.acceptedAndNotCameInfo || 0), 3))
                    .fill(0)
                    .map(() => (
                      <div className="w-2 h-2 ml-1 bg-green-500 rounded-full" />
                    ))}
                </div>
                <div className="flex items-center h-full ml-auto">
                  {apps?.pending > 3 && <p className="text-xs text-amber-500">+</p>}
                  {Array(Math.min(apps.pending || 0, 3) || 0)
                    .fill(0)
                    .map(() => (
                      <div className="flex w-2 h-2 ml-1 rounded-full bg-amber-500" />
                    ))}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
