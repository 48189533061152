import { FETCH } from "classes/Fetch";
import  { Create , Pin , GetPagePublic , GetPageMine , Delete } from "./interfaces"

class ReviewApi { 
  private readonly r: string = "review";

  constructor() {}

  // Auhorization: [{"t":"User","a":true}]
  public Create = (data: Create) => {
    return FETCH({
      path: `${this.r}/create`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public Pin = (data: Pin) => {
    return FETCH({
      path: `${this.r}/pin`,
      ...data,
    });
  };

  public GetPagePublic = (data: GetPagePublic) => {
    return FETCH({
      path: `${this.r}/get-page-public`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true},{"t":"Admin"}]
  public GetPageMine = (data: GetPageMine) => {
    return FETCH({
      path: `${this.r}/get-page-mine`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public Delete = (data: Delete) => {
    return FETCH({
      path: `${this.r}/delete`,
      ...data,
    });
  }; 
}

export const reviewApi = new ReviewApi()
