import { FETCH } from "classes/Fetch";
import  { Create , Like , GetPage } from "./interfaces"

class MessageApi { 
  private readonly r: string = "message";

  constructor() {}

  // Auhorization: [{"t":"User","a":true},{"t":"Association","org":true}]
  public Create = (data: Create) => {
    return FETCH({
      path: `${this.r}/create`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User","a":true},{"t":"Association","org":true}]
  public Like = (data: Like) => {
    return FETCH({
      path: `${this.r}/like`,
      ...data,
    });
  };

  // Auhorization: [{"t":"User"},{"t":"Association","org":true}]
  public GetPage = (data: GetPage) => {
    return FETCH({
      path: `${this.r}/get-page`,
      ...data,
    });
  }; 
}

export const messageApi = new MessageApi()
