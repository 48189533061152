import { FETCH } from "classes/Fetch";
import  { Create , UpdateDetails , UpdateStartAndEndDates , UpdateState , AddWeeklySchedulesTemplate , ModifyOneWeeklyScheduleTemplate , DeleteOneWeeklyScheduleTemplate , AddOneExceptionToOneWeeklyScheduleTemplate , GetBySlug , GetBySlugMine , GetPage , GetPageMine , GetPageTemplates , Delete , UpdateImage , DeleteImage } from "./interfaces"

class MissionApi { 
  private readonly r: string = "mission";

  constructor() {}

  // Auhorization: [{"t":"Association","org":true}]
  // Pour setup les weeklySchedules, il y'a d'autres requêtes.
  public Create = (data: Create) => {
    return FETCH({
      path: `${this.r}/create`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public UpdateDetails = (data: UpdateDetails) => {
    return FETCH({
      path: `${this.r}/update-details`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  // If the mission doesn't have a start and end dates, we can't configure templates. I need both.
  public UpdateStartAndEndDates = (data: UpdateStartAndEndDates) => {
    return FETCH({
      path: `${this.r}/update-start-and-end-dates`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public UpdateState = (data: UpdateState) => {
    return FETCH({
      path: `${this.r}/update-state`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public AddWeeklySchedulesTemplate = (data: AddWeeklySchedulesTemplate) => {
    return FETCH({
      path: `${this.r}/add-weekly-schedules-template`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public ModifyOneWeeklyScheduleTemplate = (data: ModifyOneWeeklyScheduleTemplate) => {
    return FETCH({
      path: `${this.r}/modify-one-weekly-schedule-template`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public DeleteOneWeeklyScheduleTemplate = (data: DeleteOneWeeklyScheduleTemplate) => {
    return FETCH({
      path: `${this.r}/delete-one-weekly-schedule-template`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public AddOneExceptionToOneWeeklyScheduleTemplate = (data: AddOneExceptionToOneWeeklyScheduleTemplate) => {
    return FETCH({
      path: `${this.r}/add-one-exception-to-one-weekly-schedule-template`,
      ...data,
    });
  };

  public GetBySlug = (data: GetBySlug) => {
    return FETCH({
      path: `${this.r}/get-by-slug`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true},{"t":"Admin"}]
  public GetBySlugMine = (data: GetBySlugMine) => {
    return FETCH({
      path: `${this.r}/get-by-slug-mine`,
      ...data,
    });
  };

  // Auhorization: "all"
  public GetPage = (data: GetPage) => {
    return FETCH({
      path: `${this.r}/get-page`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true},{"t":"Admin"}]
  public GetPageMine = (data: GetPageMine) => {
    return FETCH({
      path: `${this.r}/get-page-mine`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true},{"t":"Admin"}]
  public GetPageTemplates = (data: GetPageTemplates) => {
    return FETCH({
      path: `${this.r}/get-page-templates`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public Delete = (data: Delete) => {
    return FETCH({
      path: `${this.r}/delete`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public UpdateImage = (data: UpdateImage) => {
    return FETCH({
      path: `${this.r}/update-image`,
      ...data,
    });
  };

  // Auhorization: [{"t":"Association","org":true}]
  public DeleteImage = (data: DeleteImage) => {
    return FETCH({
      path: `${this.r}/delete-image`,
      ...data,
    });
  }; 
}

export const missionApi = new MissionApi()
