import { deepCopy, objectTester } from "libraries/dash/object";
import { useEffect, useState } from "react";
import { useRequest } from "./useRequest";

type Options<T> = {
  initialFilters?: any;
  dependencies?: any[];
  pageProps?: T;
  noFetch?: boolean;
};

type PaginationPropsType = { data?: any; param?: any };

type UsePagination = <T extends PaginationPropsType>(
  getPage: (data: T) => Promise<any>,
  { initialFilters, dependencies, pageProps, noFetch }: Options<T>
) => {
  pageData: PageData;
  setPageData: (v: PageData) => void;
  reset: () => void;
  loading: boolean;
  filters: any;
  setFilters: (f: any) => void;
  page: number;
  setPage: (p: number) => void;
  add: (a: any) => void;
  del: (...values: string[]) => void;
};

type PageData = {
  data: any[];
  page: number;
  total: number;
  pages: number;
  perPage: number;
};

export const usePagination: UsePagination = (
  getPage,
  { initialFilters, dependencies = [], pageProps, noFetch }
) => {
  const [page, setPage] = useState<number>(0);
  const [filters, setFilters] = useState<any>({ ...initialFilters });
  const [previousData, setPreviousData] = useState<any>({});
  const [fetched, setFetched] = useState<boolean>(false);
  const [pageData, setPageData] = useState<PageData>({
    data: [],
    total: 0,
    page: 0,
    pages: 0,
    perPage: 0,
  });

  const reset = () => {
    setPage(0);
    setFilters({ ...initialFilters });
    setPreviousData({});
  };

  useEffect(reset, [...dependencies]);

  const { req, loading } = useRequest(getPage, { success: (res: any) => setPageData(res) });

  const param = { page, ...pageProps?.param, ...filters?.param };
  const data = { ...pageProps?.data, ...filters?.data };

  useEffect(() => {
    if (objectTester({ ...pageProps, data, param }, previousData)) return;
    if (noFetch) return;
    fetch();
  }, [page, filters]);

  const fetch = () => {
    setPreviousData(deepCopy({ ...pageProps, data, param }));
    req({ ...pageProps, data, param } as any);
    setFetched(true);
  };

  const del = (...values: string[]) => {
    if (pageData.data.length < pageData.perPage && values.length > 0)
      setPageData({
        ...pageData,
        data: pageData.data.filter((d) => !values.includes(d._id)),
        total: pageData.total - 1,
      });
    else if (pageData.data.length > values.length) fetch();
    else if (pageData.page > 0) setPage(page - 1);
    else setPageData({ ...pageData, data: [] });
  };

  const add = (elem: any) => {
    if (pageData.data.length == pageData.perPage) {
      if (page == 0) fetch();
      else setPage(0);
    } else setPageData({ ...pageData, data: [...pageData.data, elem], total: pageData.total + 1 });
  };

  return {
    pageData,
    setPageData,
    reset,
    loading: loading || !fetched,
    filters,
    setFilters,
    page,
    setPage,
    add,
    del,
  };
};

type UseAllPagination = <T extends PaginationPropsType>(
  getPage: (data: T) => Promise<any>,
  { initialFilters, dependencies, pageProps, noFetch }: Options<T>
) => {
  pageData: PageData;
  setPageData: (v: PageData) => void;
  reset: () => void;
  loading: boolean;
  filters: any;
  setFilters: (f: any) => void;
};

export const useAllPagination: UseAllPagination = (
  getPage,
  { initialFilters, dependencies = [], pageProps, noFetch }
) => {
  const [filters, setFilters] = useState<any>({ ...initialFilters });
  const [previousData, setPreviousData] = useState<any>({});
  const [fetched, setFetched] = useState<boolean>(false);
  const [pageData, setPageData] = useState<PageData>({
    data: [],
    total: 0,
    page: 0,
    pages: 0,
    perPage: 0,
  });

  const reset = () => {
    setFilters({ ...initialFilters });
    setPreviousData({});
  };

  useEffect(reset, [...dependencies]);

  const { req, loading } = useRequest(getPage, {
    success: (res) => {
      if (res.pages > 1) {
        Promise.all(
          Array(res.pages - 1)
            .fill(res.pages - 1)
            .fill(0)
            .map((_: any, i: number) =>
              getPage({ ...pageProps, data, param: { ...param, page: i + 1, perPage: 100 } } as any)
            )
        )
          .then((values) => ({
            ...res,
            data: [
              ...(res.data.applications || []),
              ...(values.reduce((acc, value) => [...acc, ...(value?.data || [])], []) || []),
            ],
          }))
          .then((pageData) => setPageData(pageData));
      } else if (res.pages === 1) setPageData(res);
    },
  });

  const param = { ...pageProps?.param, ...filters?.param };
  const data = { ...pageProps?.data, ...filters?.data };

  useEffect(() => {
    if (objectTester({ ...pageProps, data, param }, previousData)) return;
    if (noFetch) return;
    fetch();
  }, [filters]);

  const fetch = () => {
    setPreviousData(deepCopy({ data, param }));
    req({ ...pageProps, data, param: { ...param, page: 0, perPage: 100 } } as any);
    setFetched(true);
  };

  return {
    pageData,
    setPageData,
    reset,
    loading: loading || !fetched,
    filters,
    setFilters,
  };
};
