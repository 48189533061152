import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/outline";

export const NextButton = ({ pageNumber, setPageNumber, total }: any): JSX.Element => (
  <li className="flex justify-end w-6 list-none " onClick={() => setPageNumber(pageNumber + 1)}>
    {pageNumber !== total - 1 ? <ChevronRightIcon className="w-6 h-6 cursor-pointer" /> : <></>}
  </li>
);

export const PreviousButton = ({ pageNumber, setPageNumber }: any): JSX.Element => (
  <span className="w-6 list-none ">
    {pageNumber !== 0 && (
      <ChevronLeftIcon
        className="w-6 h-6 cursor-pointer"
        onClick={() => setPageNumber(pageNumber - 1)}
      />
    )}
  </span>
);
